import React, { useEffect, useRef, useState } from "react";
import "./ConfirmPhone.css";
import forgotLogo from "../../images/forgot-logo.png";
import device from "../../images/device.png";
import device2 from "../../images/Device -wait.png";
import device3 from "../../images/Device -right.png";
import device4 from "../../images/Device -password.png";
import device5 from "../../images/Device -wrong.png";
import padlock from "../../images/padlock.png";
import "react-phone-input-2/lib/bootstrap.css";
import PhoneInput from "react-phone-input-2";
import { useNavigate, useParams } from "react-router-dom";
import InfoModal from "../../Components/InfoModal/InfoModal";
import notification from "../../images/notification-logo.png";
import { HEADERS } from "../../Constants/Auth";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { SendSms } from "../../Apis/Members/Models/SendSms";
import { ValidateSms } from "../../Apis/Members/Models/ValidateSms";
import { useDispatch, useSelector } from "react-redux";
import { setAcitveNav } from "../../redux/features/userSlice";

const ConfirmPhone = () => {
  const { Id } = useParams();
  const navigate = useNavigate();
  const [step, setStep] = useState(1);
  const [phoneNumberString, setPhoneNumberString] = useState("");
  const [show, setShow] = useState(false);
  const [otp, setOtp] = useState("");
  const [otpVal, setOtpVal] = useState([]);
  const verification = ["1", "2", "3", "4", "5", "6"];
  const [userOtpInfo, setUserOtpInfo] = useState();
  const year = new Date().getFullYear();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);

  const textBase = useRef(null);

  const focusNext = (e) => {
    if (textBase && textBase.current && e.target.value) {
      const childCount = textBase.current.childElementCount;
      const currentIndex = [...e.target.parentNode.children].indexOf(e.target);
      if (currentIndex !== childCount - 1) {
        e.target.nextSibling.focus();
      }
      const values = [];
      textBase.current.childNodes.forEach((child) => {
        values.push(child.value);
      });
      if (values.length === 6) {
        setOtpVal(values);
      }
    }
  };

  const handleKeyUp = (e) => {
    if (e && e.key === "Backspace" && e.target && e.target.previousSibling) {
      e.target.previousSibling.focus();
    }
  };

  const handlePaste = (e) => {
    if (e.clipboardData.getData("text")) {
      const pasteVal = e.clipboardData.getData("text");
      if (pasteVal.length === 6) {
        if (textBase && textBase.current) {
          const vals = pasteVal.split("");
          setOtpVal(vals);
          textBase.current.childNodes.forEach((child, k) => {
            child.value = vals[k];
          });
        }
      }
    }
  };

  const handleClose = () => setShow(false);

  // const sendOtp = () => {
  //   try {
  //     var raw = JSON.stringify({
  //       MembersId: parseInt(Id),
  //     });

  //     var requestOptions = {
  //       method: "POST",
  //       headers: HEADERS(),
  //       body: raw,
  //       redirect: "follow",
  //     };

  //     fetch(
  //       "https://api.softsmartinc.com/Services/v2/Bee/Members/Otp/SendSms",
  //       requestOptions
  //     )
  //       .then((response) => response.json())
  //       .then((data) => {
  //         if (data.Status === "Successful!") {
  //           setShow(true);
  //         } else {
  //           toast.warning("User not found");
  //         }
  //       });
  //   } catch (error) {
  //     console.log("error", error);
  //   }
  // };

  const sendOtp = async () => {
    try {
      const classRef = new SendSms();
      await classRef.List({
        MembersId: parseInt(Id)
      })
      let result = classRef.ResponseBody;

      if (result.Status === "Successful!") {
        setShow(true);
      } else {
        toast.warning("User not found");
      }

    } catch (error) {
      console.log("Error", error)
    }
  };

  const verifyOtp = async () => {
    const otpString = otpVal.join("");

    try {
      const classRef = new ValidateSms();
      await classRef.List({
        MembersId: parseInt(Id),
        IsEmail: false,
        Otp: otpString,
      })

      let data = classRef.ResponseBody;
      if (data.OtpValid === "VALID") {
        setStep(2);
        setUserOtpInfo(data);
        toast.success("Your phone is already verify!");
        setTimeout(() => {
          dispatch(setAcitveNav("home"))
          navigate("/");
        }, 1500);
      } else {
        setStep(3);
      }
    } catch (error) {
      console.log("Error", error)
    }
  };

  useEffect(() => {
    sendOtp();
  }, []);

  return (
    <div className="forgotPasswword-principal-container">
      <div className="forgot-main w-100">
        <div className="row forgot-main-container">
          <div className="col-xxl-3 col-xl-4 col-lg-5 col-md-6 col-sm-12 col-xs-12">
            <div className="forgot-img-container">
              <img
                src={forgotLogo}
                alt="confidential-bee"
                onClick={() => {
                  dispatch(setAcitveNav("home"))
                  navigate("/");
                }}
                style={{ cursor: "pointer" }}
              />
            </div>

            {step === 1 ? (
              <div className="forgot-container">
                <div className="forgot-device">
                  <img src={device2} alt="device-wait" />
                </div>
                <div className="forgot-container-title">Phone validation</div>
                <div className="forgot-container-content">
                  Enter the validation code texted to you
                </div>
                <div className="forgot-green-line"></div>
                <div
                  className="mb-3 mt-3"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    width: "80%",
                  }}
                >
                  <div className="h-screen flex justify-center items-center space-x-2">
                    <div className="d-flex opt-text-cn align-items-center justify-content-center mt-4">
                      <div className="otp-base d-flex" ref={textBase}>
                        {new Array(6).fill(null).map((input, k) => {
                          return (
                            <input
                              key={k}
                              onPaste={handlePaste}
                              onKeyUp={handleKeyUp}
                              type="text"
                              pattern="\d*"
                              className="form-control otp-txt"
                              maxLength={1}
                              onChange={(e) => focusNext(e)}
                            />
                          );
                        })}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="forgot-green-line2"></div>
                <div
                  className="mt-4"
                  style={{
                    width: "80%",
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <button
                    className="btn btn-primary  mb-3"
                    style={{ height: "50px", width: "120px" }}
                    onClick={() => {
                      if (otpVal) {
                        verifyOtp();
                      } else {
                        console.log("fallo");
                        console.log("otpVal", otpVal);
                        console.log("verification", verification);
                      }
                    }}
                  >
                    Verify
                  </button>

                  <div
                    className="mb-3 re-send-button"
                    style={{ height: "50px", width: "120px" }}
                    onClick={() => {
                      sendOtp()
                    }}
                  >
                    Re-Send
                  </div>
                </div>
              </div>
            ) : null}

            {step === 2 ? (
              <div className="forgot-container">
                <div className="forgot-device">
                  <img src={device3} alt="device-right" />
                </div>
                <div className="forgot-container-title">
                  Your phone is now <br /> verifield!
                </div>
                <div className="forgot-container-content"></div>
                <div className="forgot-green-line"></div>

                <div className="" style={{ width: "80%", marginTop: "100px" }}>
                  <button
                    className="btn btn-primary w-100 mb-3"
                    style={{ height: "50px" }}
                    onClick={() => {
                      setStep(3);
                    }}
                  >
                    Continue
                  </button>
                </div>
              </div>
            ) : null}

            {step === 3 ? (
              <div className="forgot-container">
                <div className="forgot-device">
                  <img src={device5} alt="device-wrong" />
                </div>
                <div className="forgot-container-title">Oops wrong code</div>
                <div className="forgot-container-content">
                  The validation code didn't work. <br /> We'll text you a new
                  one
                </div>
                <div className="forgot-green-line"></div>

                <div className="" style={{ width: "80%", marginTop: "100px" }}>
                  <button
                    className="btn btn-primary w-100 mb-3"
                    style={{ height: "50px" }}
                    onClick={() => {
                      setStep(1);
                    }}
                  >
                    Try Again
                  </button>
                </div>
              </div>
            ) : null}
          </div>
        </div>
      </div>
      <div className="forgot-footer">
        <span className="forgot-footer-title">© Copyright {year} NDABee.</span>
      </div>

      <InfoModal
        notificationImage={notification}
        notificationTitle="Notification"
        show={show}
        handleClose={handleClose}
        notificationMessage={
          <div>
            We just sent you a text message,
            <br />
            please check your phone.
          </div>
        }
      />

      <ToastContainer theme="colored" position="top-right" autoClose={2000} />
    </div>
  );
};

export default ConfirmPhone;
