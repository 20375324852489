import React, { useEffect, useState } from "react";
import "./Dashboard.css";
import DashboardCard from "../../Components/DashboardCard/DashboardCard";
import image1 from "../../images/dc-message.png";
import image2 from "../../images/dc-phone.png";
import image3 from "../../images/dc-setup.png";
import noConfrim from "../../images/dc-noConfirm.png";
import confrim from "../../images/dc-confirm.png";
import {
  BASE_URL,
  HEADERS,
  SERVICES,
  SERVICES_NAME,
  VERSION,
} from "../../Constants/Auth";
import { USER_ID, USER_TOKEN } from "../../Constants/User";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import ndalogo from "../../images/nda-logo.png";
import ownnda from "../../images/own-nda-logo.png";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { setupSuccess } from "../../redux/features/setupSlice";
import InfoModal from "../../Components/InfoModal/InfoModal";
import InfoModal2 from "../../Components/InfoModal/InfoModal2";
import notification from "../../images/notification-logo.png";
import { getAuth, signInAnonymously } from "firebase/auth";
import { messaging } from "./../../services/firebase";
import { getToken, onMessage } from "firebase/messaging";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { hideLoading, showLoading } from "../../redux/features/alertSlice";
import { Get } from "../../Apis/Members/Models/Get";
import { GetByMember } from "../../Apis/Accounts/Models/GetByMember";
import free from "../../images/Free.png";
import OtherCards from "../../Components/OtherpagesCard/OtherCards";
import beeIcon from "../../images/beeIcon.png";
import billIcon from "../../images/billIcon.png";
import softsmart from "../../images/softsmartIcon.png";
import { CustomRequest } from "../../Apis/Documents/Models/CustomRequest";

const Dashboard = () => {
  const baseUrl = BASE_URL + SERVICES + "/" + VERSION + "/" + SERVICES_NAME;
  const currentUserId = USER_ID();
  const [userInfo, setUserInfo] = useState();
  const [accountInfo, setAccountInfo] = useState();
  const [addressInfo, setAddressInfo] = useState();
  const [entityInfo, setEntityInfo] = useState();
  const [verifications, setVerifications] = useState(false);
  const [show, setShow] = useState(false);
  const [show2, setShow2] = useState(false);
  const [show3, setShow3] = useState(false);
  const [email, setEmail] = useState(false);
  const [phone, setPhone] = useState(false);
  const [entity, setEntity] = useState(false);
  const [getByMemberError, setGetByMemberError] = useState(false);
  const { isSetup } = useSelector((state) => state.setup);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const year = new Date().getFullYear();

  const handleClose = () => setShow(false);
  const handleClose2 = () => setShow2(false);
  const handleClose3 = () => setShow3(false);

  const sendCustomRequest = async () => {
    const classRef = new CustomRequest();
    await classRef.List({MembersId: currentUserId})
    let result = classRef.ResponseBody;

    if(result.Status === "Successful!"){
      toast.success("Your custom NDA was successfully requested.");
       setShow2(false);
    }
  }

  const getUserInfo = async () => {
    const classRef = new Get();
    await classRef.List({ MembersId: currentUserId });
    let result = classRef.ResponseBody;

    if (result) {
      setUserInfo(result.MemberInfo);
      if (result.MemberInfo.IsEmailVerified) {
        setEmail(true);
      }
      if (result.MemberInfo.IsPhoneVerified) {
        setPhone(true);
      }
    }
  };

  const getAccountInfo = async () => {
    try {
      dispatch(showLoading());
      const classRef = new GetByMember();
      await classRef.List({ MembersId: currentUserId });
      let result = classRef.ResponseBody;

      dispatch(hideLoading());
      if (result.AccountInfo) {
        setAccountInfo(result.AccountInfo);
        setAddressInfo(result.AddressInfo);
        setEntityInfo(result.EntityTypeInfo);
        if (result.AccountInfo.FilesId_Signature > 0) {
          setEntity(true);
        }
        console.log(result.EntityTypeInfo);
      } else {
        setGetByMemberError(true);
      }
    } catch (error) {
      console.log("Error", error);
    }
  };

  const handleVerify = () => {
    if (email && phone && entity) {
      return true;
    } else {
      return false;
    }
  };

  /*firebase*/

  // const activarMensajes = async ()=> {
  //   const token = await getToken(messaging,{
  //     vapidKey: "BBCvdIf1vSvAMD1G5RMXxbnrqRLuNg4Adi5UQOvijPcU3msMIKrlPg7pquv-Ar_ZBOBUGpvsHJv-8YfTM8_BlSA"
  //   }).catch(error => console.log("Tuviste un error al generar el token, papá"));

  //   if(token) console.log("tu token:",token);
  //   if(!token) console.log("no tienes token, rey");
  // }

  // useEffect(()=>{
  //   activarMensajes();
  //   onMessage(messaging, message=>{
  //     console.log("tu mensaje:", message);
  //     toast(message.notification.title);
  //   })
  //   }, []);

  // useEffect(() => {
  //   const handleNotification = async () => {
  //     try {
  //       await Notification.requestPermission();

  //       const token = await getToken(messaging,{
  //         vapidKey: "BBCvdIf1vSvAMD1G5RMXxbnrqRLuNg4Adi5UQOvijPcU3msMIKrlPg7pquv-Ar_ZBOBUGpvsHJv-8YfTM8_BlSA"
  //       });
  //       console.log("Token:", token);

  //       onMessage((message) => {
  //         console.log("Mensaje recibido:", message);
  //       });
  //     } catch (error) {
  //       console.error("Error al obtener el token:", error);
  //     }
  //   };

  //   handleNotification();
  // }, []);

  useEffect(() => {
    getUserInfo();
    getAccountInfo();
  }, []);
  return (
    <>
      <div className="dashboard-principal-container">
        {!isSetup && handleVerify() === false ? (
          <>
            {/*title */}
            <div className="d-flex  mt-5 d-title-container">
              Verify and Setup
            </div>
            <div className="d-greenline-container mt-3">
              <div className="d-greenline"></div>
            </div>

            {/*Main section */}
            <div className="d-main">
              <div className="d-main-info">
                <DashboardCard
                  confirm={userInfo?.IsEmailVerified ? confrim : noConfrim}
                  img={image1}
                  title="Verify your Email!"
                  button="Confirm Email"
                  subtitle="Please verify your Email"
                  disabled={userInfo?.IsEmailVerified ? true : false}
                  onClick={() => {
                    navigate(`/verify-email/${currentUserId}`);
                  }}
                />
                <DashboardCard
                  confirm={userInfo?.IsPhoneVerified ? confrim : noConfrim}
                  img={image2}
                  title="Verify your Phone!"
                  button="Confirm Phone"
                  subtitle="Please verify your Phone"
                  disabled={userInfo?.IsPhoneVerified ? true : false}
                  onClick={() => {
                    if (getByMemberError) {
                      toast.warning("Please setup your entity first");
                    } else {
                      navigate(`/verify-phone/${currentUserId}`);
                    }
                  }}
                />
                <DashboardCard
                  confirm={
                    accountInfo?.FilesId_Signature > 0 ? confrim : noConfrim
                  }
                  img={image3}
                  title="Please Setup your Entity"
                  button="Setup"
                  subtitle="Please Setup your Entity"
                  disabled={userInfo?.IsLockedOut ? true : false}
                  onClick={() => {
                    navigate(`/verify-entity/${currentUserId}`);
                  }}
                />
              </div>

              <div
                className="d-greenline"
                style={{ backgroundColor: "#0572D2", marginTop: "40px" }}
              ></div>
              <button
                type="button"
                className="btn btn-outline-primary skip"
                onClick={() => {
                  // setVerifications(true);
                  dispatch(setupSuccess());
                }}
              >
                {" "}
                Skip for Now
              </button>
            </div>
          </>
        ) : (
          <div className="home-dashboard-container">
            <div className="home-dashboard-title">Welcome</div>
            <div className="home-dashboard-greenline"></div>
            <div className="row w-100 mt-5 mobile-home-container">
              {/* <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12 center-info-left">
                <div className="send-nda-containe-right">
                  <div className="left-nda">
                    <img src={ndalogo} alt="logo-nda" />
                  </div>
                  <div className="right-nda">
                    <div className="w-100 d-flex justify-content-between align-items-center">
                      <div className="right-nda-title">Send NDA</div>
                      <div className="right-nda-title">
                        {userInfo?.NdaPrices.Price} $
                      </div>
                    </div>
                    <div className="right-nda-text">
                      Work with our standard NDA and send it to business
                      partners for quick turnaround!
                    </div>
                    <button
                      type="button"
                      className="btn btn-light mt-3"
                      style={{ width: "70%", color: "#0572D2" }}
                      onClick={() => {
                        // if(entityInfo?.FilesId_Signature >= 0){
                        //  return setShow(true)
                        // } else{
                        //   navigate(`/send-nda/${currentUserId}`);
                        // }
                        if (!accountInfo) {
                          setShow(true);
                          setTimeout(() => {
                            navigate(`/verify-entity/${currentUserId}`);
                          }, 1500);
                        } else {
                          navigate(`/send-nda/${currentUserId}`);
                        }
                      }}
                    >
                      Select
                    </button>
                  </div>
                </div>
              </div>
              <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12 center-info-right">
                <div className="send-nda-containe-left">
                  <div className="left-nda">
                    <img src={ownnda} alt="logo-nda" />
                  </div>
                  <div className="right-nda">
                    <div className="right-nda-title">
                      Want to use Your Own NDA?
                    </div>
                    <div className="right-nda-text">
                      We can work with your NDA if you prefer. Send us your NDA
                      format and we will integrate it into your account for your
                      use only!
                    </div>
                    <button
                      type="button"
                      className="btn btn-light"
                      style={{ width: "70%", color: "#0572D2" }}
                      onClick={() => {
                        setShow2(true);
                        // setTimeout(()=>{
                        //   setShow2(false)
                        // },1500)
                      }}
                    >
                      Select
                    </button>
                  </div>
                </div>
              </div> */}
              <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 center-info">
                <div
                  className="send-nda-containe-right"
                  onClick={() => {
                    if (!accountInfo) {
                      setShow(true);
                      setTimeout(() => {
                        navigate(`/verify-entity/${currentUserId}`);
                      }, 1500);
                    } else {
                      navigate(`/send-nda/${currentUserId}`);
                    }
                  }}
                >
                  <div className="left-nda">
                    <img src={ndalogo} alt="logo-nda" />
                  </div>
                  <div className="right-nda">
                    <div className="w-100 d-flex justify-content-between align-items-center">
                      <div className="right-nda-title">Send NDA</div>
                      <div className="right-nda-title">
                        {/* {userInfo?.NdaPrices.Price} $ */}
                      </div>
                    </div>
                    <div className="right-nda-text">
                      Work with our standard NDA and send it to business
                      partners for quick turnaround!
                    </div>
                    {/* <button
                      type="button"
                      className="btn btn-light mt-3"
                      style={{ width: "70%", color: "#0572D2" }}
                      onClick={() => {
                        if (!accountInfo) {
                          setShow(true);
                          setTimeout(() => {
                            navigate(`/verify-entity/${currentUserId}`);
                          }, 1500);
                        } else {
                          navigate(`/send-nda/${currentUserId}`);
                        }
                      }}
                    >
                      Select
                    </button> */}
                    <div className="w-100 mt-2 d-flex justify-content-between align-items-center">
                      <div className="btn-offer">$4.99</div>
                      <div>
                        <img src={free} alt="free-logo" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 center-info2 mt-4">
                <OtherCards
                  background="linear-gradient(to top, rgba(0, 154, 255, 1), rgba(133, 207, 255, 1))"
                  backgroundModel="#06B576"
                  backgroundFooter="#0572D2"
                  backgroundLine="#FFF"
                  image={beeIcon}
                  text1="Want to use"
                  text2="Your Own NDA?"
                  colorText1="#fff"
                  colorText2="#fff"
                  onClick={() => {
                    setShow2(true);
                  }}
                />
                <Link to="https://billsimplicity.com/" target="_blank">
                  <OtherCards
                    background="linear-gradient(to top, rgba(243, 243, 243, 1), rgba(255, 255, 255, 1))"
                    backgroundModel="#1674D1"
                    backgroundFooter="#4A4A4A"
                    backgroundLine="#0572D2"
                    image={billIcon}
                    text1="Let Us Handle"
                    text2="Your Bills!"
                    colorText1="#0572D2"
                    colorText2="#4A4A4A"
                  />
                </Link>
                <Link to="https://softsmartinc.com/" target="_blank">
                  <OtherCards
                    background="linear-gradient(to top, rgba(13, 76, 155, 1), rgba(17, 109, 223, 1))"
                    backgroundModel="#FF5B02"
                    backgroundFooter="#6DAF48"
                    backgroundLine="#FFF"
                    image={softsmart}
                    text1="We are expert mobile /n app deveolpers"
                    text2="Click Here for a Quote!"
                    colorText1="#fff"
                    colorText2="#fff"
                  />
                </Link>
                <OtherCards
                  background="linear-gradient(to top, rgba(6, 181, 118, 1), rgba(1, 227, 145, 1))"
                  backgroundModel="#2361AE"
                  backgroundFooter="#FFF"
                  backgroundLine="#FFF"
                  image={beeIcon}
                  text1="Need Help with Contracts?"
                  text2="Our Legal Department can Assist!"
                  colorText1="#fff"
                  colorText2="#fff"
                  onClick={() => {
                    setShow3(true);
                  }}
                />
              </div>
            </div>
          </div>
        )}

        {/*footer */}
        <div className="d-footer">© Copyright {year} NDABee.</div>
      </div>
      <ToastContainer theme="colored" position="top-right" autoClose={2000} />
      <InfoModal
        notificationImage={notification}
        notificationTitle="Notification"
        show={show}
        handleClose={handleClose}
        notificationMessage={
          <div>
            Before sending an NDA,
            <br />
            please set up your signature.
          </div>
        }
      />
      <InfoModal2
        notificationImage={notification}
        cancel={() => {
          sendCustomRequest();
          // const email = "info@confidentialbee.com";
          // const subject = "Request for Custom NDA";
          // const body = `Dear Support Team,\n\nWe are interested in requesting a custom NDA that fits our specific needs. We would appreciate your guidance on the process to create an appropriate agreement.\n\nWe look forward to your response.\n\nBest regards,`;
          // const mailtoLink = `mailto:${email}?subject=${encodeURIComponent(
          //   subject
          // )}&body=${encodeURIComponent(body)}`;
          // console.log("Mailto link:", mailtoLink);
          // window.location.href = mailtoLink;
        }}
        submit={() => {
          setShow2(false);
        }}
        notificationTitle="Request Custom NDA"
        show={show2}
        handleClose={handleClose2}
        notificationMessage={
          <div>
            To request your own document, click here to notify our Customer
            Service department and someone will reach out to you shortly!
          </div>
        }
      />
       <InfoModal2
        notificationImage={notification}
        cancel={() => {
          setShow3(false);
          const email = "info@confidentialbee.com";
          const subject = "Request for Custom Contract Assistance";
          const body = `Dear Legal Team,\n\nWe need assistance with creating custom contracts that meet our specific needs. Your guidance on the process to draft these agreements would be greatly appreciated.\n\nWe look forward to your response.\n\nBest regards,`;
          const mailtoLink = `mailto:${email}?subject=${encodeURIComponent(
            subject
          )}&body=${encodeURIComponent(body)}`;
          console.log("Mailto link:", mailtoLink);
          window.location.href = mailtoLink;
        }}
        submit={() => {
          setShow3(false);
        }}
        notificationTitle="Help with contracts"
        show={show3}
        handleClose={handleClose3}
        notificationMessage={
          <div>
             If you need assistance with creating custom contracts, click here to get in touch with our Legal Department. They will provide the support you need to ensure your contracts meet all your requirements.
          </div>
        }
      />
    </>
  );
};

export default Dashboard;
