import React, { useEffect, useState } from "react";
import "./Docs.css";
import DocumentCard from "../../Components/DocumentCard/DocumentCard";
import { USER_ID } from "./../../Constants/User";
import avatar2 from "../../images/avatar.png";
import { Documents } from "../../Apis/List/Models/Documents";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { hideLoading, showLoading } from "../../redux/features/alertSlice";
import { setAcitveNav } from "../../redux/features/userSlice";

const Docs = () => {
  const [stepInfo, setStepInfo] = useState(0);
  const [listDocumentSend, setListDocumentSend] = useState([]);
  const [listDocumentReceived, setListDocumentReceived] = useState();
  const [listDocumentRead, setListDocumentRead] = useState();
  const currentUserId = USER_ID();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const year = new Date().getFullYear();

  const listNDA = async () => {
    dispatch(showLoading());

    const classRef = new Documents();
    await classRef.List({ MembersId: parseInt(currentUserId) });
    let result = classRef.ResponseBody;

    result.ListDocumentsSent.sort((a, b) => {
      const dateA = new Date(a.CreatedOn);
      const dateB = new Date(b.CreatedOn);
      return dateB - dateA;
    });

    result.ListDocumentsReceived.sort((a, b) => {
      const dateA = new Date(a.CreatedOn);
      const dateB = new Date(b.CreatedOn);
      return dateB - dateA;
    });

    dispatch(hideLoading());
    setListDocumentSend(result.ListDocumentsSent);
    setListDocumentReceived(result.ListDocumentsReceived);
  };

  useEffect(() => {
    dispatch(setAcitveNav("docs"))
    listNDA();
  }, []);
  return (
    <div className="docs-principal-container">
      <div className="main-doc-container">
        <div className="row w-100 d-flex justify-content-center">
          <div className="center-info-right d-flex justify-content-center">
            <div className="nda-info-container2">
              <div className="nda-info-title">NDA</div>
              <div className="w-100 d-flex justify-content-center mt-2">
                <div className="info-greenline"></div>
              </div>
              <div className="info-tabs-conteiner">
                <div
                  onClick={() => {
                    setStepInfo(0);
                  }}
                  style={{
                    color: `${
                      stepInfo === 0 ? "#383E45" : "rgba(56, 62, 69, 0.5)"
                    }`,
                    paddingBottom: `${stepInfo === 0 ? "5px" : "0px"}`,
                    borderBottom: `${
                      stepInfo === 0
                        ? "2px solid #06B576"
                        : "1px solid transparent"
                    }`,
                  }}
                >
                  All
                </div>
                <div
                  onClick={() => {
                    setStepInfo(1);
                  }}
                  style={{
                    color: `${
                      stepInfo === 1 ? "#383E45" : "rgba(56, 62, 69, 0.5)"
                    }`,
                    paddingBottom: `${stepInfo === 1 ? "5px" : "0px"}`,
                    borderBottom: `${
                      stepInfo === 1
                        ? "2px solid #06B576"
                        : "1px solid transparent"
                    }`,
                  }}
                >
                  Incoming
                </div>
                <div
                  onClick={() => {
                    setStepInfo(2);
                  }}
                  style={{
                    color: `${
                      stepInfo === 2 ? "#383E45" : "rgba(56, 62, 69, 0.5)"
                    }`,
                    paddingBottom: `${stepInfo === 2 ? "5px" : "0px"}`,
                    borderBottom: `${
                      stepInfo === 2
                        ? "2px solid #06B576"
                        : "1px solid transparent"
                    }`,
                  }}
                >
                  Outgoing
                </div>
                <div
                  onClick={() => {
                    setStepInfo(3);
                  }}
                  style={{
                    color: `${
                      stepInfo === 3 ? "#383E45" : "rgba(56, 62, 69, 0.5)"
                    }`,
                    paddingBottom: `${stepInfo === 3 ? "5px" : "0px"}`,
                    borderBottom: `${
                      stepInfo === 3
                        ? "2px solid #06B576"
                        : "1px solid transparent"
                    }`,
                  }}
                >
                  Executed
                </div>
              </div>

              {stepInfo === 0 ? (
                <div className="documents-container">
                  <div
                    className="mb-4"
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      width: "100%",
                    }}
                  >
                    <label
                      htmlFor="exampleFormControlInput10"
                      className="form-label label-title"
                    >
                      Search
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      //   value={formulario.Email}
                      name="Search"
                      //   onChange={handleChange2}
                      style={{ padding: "10px", height: "52px" }}
                      id="exampleFormControlInput10"
                      placeholder={`🔎 Search`}
                      required
                      autoComplete="false"
                    />
                  </div>
                  <div className="container01">
                    {listDocumentSend?.map((item, index) => {
                      const dateTimeParts = item.CreatedOn.split(" ");
                      const date = dateTimeParts[0].replace(/-/g, "/");
                      const timeParts = dateTimeParts[1].split(":");
                      const time = `${timeParts[0]}:${timeParts[1]} ${dateTimeParts[2]}`;
                      return (
                        <DocumentCard
                          key={index}
                          img="out"
                          name={item.InvitationName}
                          date={date}
                          hour={time}
                          status={
                            item?.DocOutStatus.Label === "Executed" &&
                            item?.DocInStatus.Label === "Executed"
                              ? "executed"
                              : "sent"
                          }
                          imgContact={item.AvatarUrl ? item.AvatarUrl : avatar2}
                          color={
                            item?.DocOutStatus.Label === "Executed" &&
                            item?.DocInStatus.Label === "Executed"
                              ? "#06b576"
                              : "#EA4335"
                          }
                          enterprise={item.EntityName}
                          onClick={() => {
                            navigate(
                              `/view-document/${item.AccountId_Recipient}/${item.Id}/false`
                            );
                          }}
                        />
                      );
                    })}

                    {listDocumentReceived?.map((item, index) => {
                      const dateTimeParts = item.CreatedOn.split(" ");
                      const date = dateTimeParts[0].replace(/-/g, "/");
                      const timeParts = dateTimeParts[1].split(":");
                      const time = `${timeParts[0]}:${timeParts[1]} ${dateTimeParts[2]}`;
                      return (
                        <DocumentCard
                          key={index}
                          img="in"
                          name={item.DisplayName}
                          date={date}
                          hour={time}
                          status={
                            item?.DocOutStatus.Label === "Executed" &&
                            item?.DocInStatus.Label === "Executed"
                              ? "read"
                              : "received"
                          }
                          imgContact={item.AvatarUrl ? item.AvatarUrl : avatar2}
                          color={
                            item?.DocOutStatus.Label === "Executed"
                              ? "#06b576"
                              : "#EBD300"
                          }
                          enterprise={item.EntityName}
                          onClick={() => {
                            navigate(
                              `/view-document/${item.AccountId_Recipient}/${item.Id}/true`
                            );
                          }}
                        />
                      );
                    })}
                  </div>
                </div>
              ) : null}

              {stepInfo === 1 ? (
                <div className="documents-container">
                  <div
                    className="mb-4"
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      width: "100%",
                    }}
                  >
                    <label
                      htmlFor="exampleFormControlInput10"
                      className="form-label label-title"
                    >
                      Search
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      //   value={formulario.Email}
                      name="Search"
                      //   onChange={handleChange2}
                      style={{ padding: "10px", height: "52px" }}
                      id="exampleFormControlInput10"
                      placeholder={`🔎 Search`}
                      required
                      autoComplete="false"
                    />
                  </div>
                  <div className="container01">
                    {listDocumentReceived?.map((item, index) => {
                      const dateTimeParts = item.CreatedOn.split(" ");
                      const date = dateTimeParts[0].replace(/-/g, "/");
                      const timeParts = dateTimeParts[1].split(":");
                      const time = `${timeParts[0]}:${timeParts[1]} ${dateTimeParts[2]}`;
                      return (
                        <DocumentCard
                          key={index}
                          img="in"
                          name={item.DisplayName}
                          date={date}
                          hour={time}
                          status={
                            item?.DocOutStatus.Label === "Executed" &&
                            item?.DocInStatus.Label === "Executed"
                              ? "executed"
                              : "received"
                          }
                          imgContact={item.AvatarUrl ? item.AvatarUrl : avatar2}
                          color={
                            item?.DocOutStatus.Label === "Executed"
                              ? "#06b576"
                              : "#EBD300"
                          }
                          enterprise={item.EntityName}
                          onClick={() => {
                            navigate(
                              `/view-document/${item.AccountId_Recipient}/${item.Id}/true`
                            );
                          }}
                        />
                      );
                    })}
                  </div>
                </div>
              ) : null}

              {stepInfo === 2 ? (
                <div className="documents-container">
                  <div
                    className="mb-4"
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      width: "100%",
                    }}
                  >
                    <label
                      htmlFor="exampleFormControlInput10"
                      className="form-label label-title"
                    >
                      Search
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      //   value={formulario.Email}
                      name="Search"
                      //   onChange={handleChange2}
                      style={{ padding: "10px", height: "52px" }}
                      id="exampleFormControlInput10"
                      placeholder={`🔎 Search`}
                      required
                      autoComplete="false"
                    />
                  </div>
                  <div className="container01">
                    {listDocumentSend?.map((item, index) => {
                      const dateTimeParts = item.CreatedOn.split(" ");
                      const date = dateTimeParts[0].replace(/-/g, "/");
                      const timeParts = dateTimeParts[1].split(":");
                      const time = `${timeParts[0]}:${timeParts[1]} ${dateTimeParts[2]}`;
                      return (
                        <DocumentCard
                          key={index}
                          img="out"
                          name={item.InvitationName}
                          date={date}
                          hour={time}
                          status={
                            item?.DocOutStatus.Label === "Executed" &&
                            item?.DocInStatus.Label === "Executed"
                              ? "executed"
                              : "sent"
                          }
                          imgContact={item.AvatarUrl ? item.AvatarUrl : avatar2}
                          color={
                            item?.DocOutStatus.Label === "Executed"
                              ? "#06b576"
                              : "#EA4335"
                          }
                          enterprise={item.EntityName}
                          onClick={() => {
                            navigate(
                              `/view-document/${item.AccountId_Recipient}/${item.Id}/false`
                            );
                          }}
                        />
                      );
                    })}
                  </div>
                </div>
              ) : null}

              {stepInfo === 3 ? (
                <div className="documents-container">
                  <div
                    className="mb-4"
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      width: "100%",
                    }}
                  >
                    <label
                      htmlFor="exampleFormControlInput10"
                      className="form-label label-title"
                    >
                      Search
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      //   value={formulario.Email}
                      name="Search"
                      //   onChange={handleChange2}
                      style={{ padding: "10px", height: "52px" }}
                      id="exampleFormControlInput10"
                      placeholder={`🔎 Search`}
                      required
                      autoComplete="false"
                    />
                  </div>
                  <div className="container01">
                    {listDocumentSend
                      ?.filter((item) => item.DocInStatus.Label === "Executed")
                      .map((item, index) => {
                        const dateTimeParts = item.CreatedOn.split(" ");
                        const date = dateTimeParts[0].replace(/-/g, "/");
                        const timeParts = dateTimeParts[1].split(":");
                        const time = `${timeParts[0]}:${timeParts[1]} ${dateTimeParts[2]}`;
                        return (
                          <DocumentCard
                            key={index}
                            img="out"
                            name={item.InvitationName}
                            date={date}
                            hour={time}
                            status={
                              item?.DocOutStatus.Label === "Executed" &&
                              item?.DocInStatus.Label === "Executed"
                                ? "executed"
                                : "sent"
                            }
                            imgContact={
                              item.AvatarUrl ? item.AvatarUrl : avatar2
                            }
                            color={
                              item?.DocOutStatus.Label === "Executed" &&
                              item?.DocInStatus.Label === "Executed"
                                ? "#06b576"
                                : "#EA4335"
                            }
                            enterprise={item.EntityName}
                            onClick={() => {
                              navigate(
                                `/view-document/${item.AccountId_Recipient}/${item.Id}/false`
                              );
                            }}
                          />
                        );
                      })}

                    {listDocumentReceived?.filter((item) => item.DocInStatus.Label === "Executed")
                    .map((item, index) => {
                      const dateTimeParts = item.CreatedOn.split(" ");
                      const date = dateTimeParts[0].replace(/-/g, "/");
                      const timeParts = dateTimeParts[1].split(":");
                      const time = `${timeParts[0]}:${timeParts[1]} ${dateTimeParts[2]}`;
                      return (
                        <DocumentCard
                          key={index}
                          img="in"
                          name={item.DisplayName}
                          date={date}
                          hour={time}
                          status={
                            item?.DocOutStatus.Label === "Executed" &&
                            item?.DocInStatus.Label === "Executed"
                              ? "read"
                              : "received"
                          }
                          imgContact={item.AvatarUrl ? item.AvatarUrl : avatar2}
                          color={
                            item?.DocOutStatus.Label === "Executed"
                              ? "#06b576"
                              : "#EBD300"
                          }
                          enterprise={item.EntityName}
                          onClick={() => {
                            navigate(
                              `/view-document/${item.AccountId_Recipient}/${item.Id}/true`
                            );
                          }}
                        />
                      );
                    })}
                  </div>
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>
      <div className="doc-footer">© Copyright {year} NDABee.</div>
    </div>
  );
};

export default Docs;
