import React from 'react'
import "./TermsOfService.css"
import Menu from '../../Components/Menu/Menu'

const TermsOfService = () => {
  const year = new Date().getFullYear();
  const user = localStorage.getItem('User')
  return (
    <div>
      {!user && ( <Menu/> )}
      <div className='term-fo-service-principal-container'>
        <div className='terms-container' style={{marginTop:`${user ? "50px" : "200px"}`}}>
          <h1 className='terms-title'>Terms of Service</h1>
          <div className='green-line-terms'>
            <div className='green-line-term'></div>
          </div>
          <h2 className='terms-subtitle mt-5'>Effective Date: 04/04/24</h2>
          <p className='terms-text'>Welcome to ConfidentialBee! These Terms of Service ("Terms") govern your use of the website <a href="https://www.confidentialbee.com/" target='_blank' rel="noreferrer">www.confidentialbee.com</a> ("Site") and the mobile application ("App") provided by ConfidentialBee. By accessing or using the Site or App, you agree to be bound by these Terms. If you disagree with any part of the Terms, you may not access the Site or App.</p>
          <ol>
            <li>
            <h3 className='terms-h3'>Use of the Site and App:</h3>
            <p className='terms-text'>By using the Site or App, you represent that you are at least the age of majority in your state or province of residence, or that you are the age of majority in your state or province of residence and you have given us your consent to allow any of your minor dependents to use this Site or App.</p>
            </li>
            <li>
            <h3 className='terms-h3'>User Account:</h3>
            <p className='terms-text'>To access certain features of the Site or App, you may be required to create an account. You are responsible for maintaining the confidentiality of your account and password and for restricting access to your computer or mobile device. You agree to accept responsibility for all activities that occur under your account or password.</p>
            </li>
            <li>
            <h3 className='terms-h3'>Intellectual Property:</h3>
            <p className='terms-text'>The Site and App and their original content, features, and functionality are owned by ConfidentialBee and are protected by international copyright, trademark, patent, trade secret, and other intellectual property or proprietary rights laws.</p>
            </li>
            <li>
            <h3 className='terms-h3'>Prohibited Activities:</h3>
            <p className='terms-text'>You agree not to use the Site or App for any unlawful purpose or to violate any laws in your jurisdiction. You also agree not to:</p>
            <ul className='ul-list'>
              <li>Post or transmit any content that is defamatory, obscene, pornographic, abusive, offensive, or otherwise violates any law or right of any third party. </li>
              <li>Interfere with or disrupt the Site or App or servers or networks connected to the Site or App. </li>
              <li>Attempt to gain unauthorized access to any portion of the Site or App or any other systems or networks connected to the Site or App.</li>
            </ul>
            </li>
            <li>
            <h3 className='terms-h3'>Limitation of Liability:</h3>
            <p className='terms-text'>In no event shall ConfidentialBee, nor its directors, employees, partners, agents, suppliers, or affiliates, be liable for any indirect, incidental, special, consequential, or punitive damages, including without limitation, loss of profits, data, use, goodwill, or other intangible losses, resulting from (i) your access to or use of or inability to access or use the Site or App; (ii) any conduct or content of any third party on the Site or App; (iii) any content obtained from the Site or App; and (iv) unauthorized access, use, or alteration of your transmissions or content, whether based on warranty, contract, tort (including negligence), or any other legal theory, whether or not we have been informed of the possibility of such damage, and even if a remedy set forth herein is found to have failed of its essential purpose.</p>
            </li>
            <li>
            <h3 className='terms-h3'>Indemnification:</h3>
            <p className='terms-text'>You agree to indemnify and hold ConfidentialBee and its affiliates, officers, directors, agents, and employees harmless from any claim or demand, including reasonable attorneys' fees, made by any third party due to or arising out of your breach of these Terms or the documents they incorporate by reference, or your violation of any law or the rights of a third party.</p>
            </li>
            <li>
            <h3 className='terms-h3'>Governing Law:</h3>
            <p className='terms-text'>These Terms shall be governed and construed in accordance with the laws of [insert jurisdiction], without regard to its conflict of law provisions.</p>
            </li>
            <li>
            <h3 className='terms-h3'>Changes to Terms:</h3>
            <p className='terms-text'>ConfidentialBee reserves the right, at our sole discretion, to modify or replace these Terms at any time. If a revision is material, we will try to provide at least 30 days' notice prior to any new terms taking effect. What constitutes a material change will be determined at our sole discretion.</p>
            </li>
          </ol>
          <h2 className='terms-subtitle2 mt-5'>Closing Statement:</h2>
          <p className='terms-text'>Thank you for reviewing our Terms of Service. By accessing or using the ConfidentialBee website or mobile application, you agree to abide by these Terms. If you have any questions or concerns about these Terms, please contact us at info@softsmart.com. Your continued use of the Site or App indicates your acceptance of these Terms.</p>
        </div>
        <div className='before-footer'>Terms of Service | Privacy Policy</div>
        <div className='footer-terms'>© Copyright {year} NDABee.</div>
      </div>
    </div>
  )
}

export default TermsOfService