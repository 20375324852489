import React, { useEffect, useState } from "react";
import * as htmlToImage from "html-to-image";
import SignatureCanvas from "react-signature-canvas";
import "./EditYourSignature.css";
import { USER_ID } from "../../Constants/User";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from 'react-router-dom';
import { GetByMember } from "../../Apis/Accounts/Models/GetByMember";
import { SignatureSave } from "../../Apis/Accounts/Models/SignatureSave";

const EditYourSignature = () => {
  const wrongSignature =
    "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAAAXNSR0IArs4c6QAAAAtJREFUGFdjYAACAAAFAAGq1chRAAAAAElFTkSuQmCC";
  const [showSingnature, setShowSignature] = useState(true);
  const [signature, setSignature] = useState("");
  const [sign, setSign] = useState();
  const [url, setUrl] = useState();
  const [memberInfo, setMemberInfo] = useState();
  const Id = USER_ID();
  const naviagte = useNavigate();
  const year = new Date().getFullYear();

  function dataURLtoBlob(dataURL) {
    const byteString = atob(dataURL.split(",")[1]);
    const mimeString = dataURL.split(",")[0].split(":")[1].split(";")[0];
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ab], { type: mimeString });
  }

  /*write signature */
  const handleSaveButtonClick = () => {
    if (signature === "") {
      console.log("Wrong signature");
      return;
    }

    htmlToImage
      .toPng(document.getElementById("signature"),{
        backgroundColor: "transparent",
      })
      .then(async function (dataUrl) {
        const formdata = new FormData();
        formdata.append("AccountId", memberInfo.AccountInfo.Id);
        formdata.append("File", dataURLtoBlob(dataUrl), "[PROXY]");

        try {
          const classRef = new SignatureSave();
          await classRef.List(formdata)
          let result = classRef.ResponseBody;

          toast.success("Signature uploaded successfully")
          console.log("Signature uploaded successfully");
          setTimeout(()=>{
            naviagte("/edit-entity")
          },1500)
        } catch (error) {
          console.log("Error", error)
        }
      })
      .catch(function (error) {
        console.error("Error al guardar la imagen:", error);
      });
  };

  /*draw signature */

  const handleClear = () => {
    sign.clear();
    setUrl("");
  };
  const handleGenerate = async () => {
    const signatureDataURL = sign.getTrimmedCanvas().toDataURL("image/png");
    if (signatureDataURL === wrongSignature) {
      console.log("Wrong Signature");
      return;
    }
    setUrl(signatureDataURL);
    const formdata = new FormData();
    formdata.append("AccountId", memberInfo.AccountInfo.Id);
    formdata.append("File", dataURLtoBlob(signatureDataURL), "[PROXY]");

    try {
      const classRef = new SignatureSave();
      await classRef.List(formdata);
      let result = classRef.ResponseBody;

      toast.success("Signature uploaded successfully")
      console.log("Signature uploaded successfully");
      setTimeout(()=>{
         naviagte("/edit-entity")
      },1500)
  

    } catch (error) {
      console.log("Error", error)
    }
  };

  const getByMember = async () => {
    try {
      const classRef = new GetByMember();
      await classRef.List({
        MembersId: parseInt(Id)
      })
      let result = classRef.ResponseBody;
      if(result.AccountInfo){
        setMemberInfo(result)
      }
    } catch (error) {
      console.log("Error", error)
    }
  };

  const registerEntity = () => {
    if (showSingnature) {
      handleGenerate();
    } else {
      handleSaveButtonClick();
    }
  };

  useEffect(()=>{
    getByMember();
  },[])
  return (
    <>
    <div className="esignature-principal-container">
      <div></div>
      <div className="esignature-container">
        <div className="step-card-container">
          <div className="step-card-title mb-0">Signature</div>
          <div className="step-card-line mb-2 mt-2"></div>
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              gap: "70px",
              marginTop: "20px",
            }}
          >
            <span
              onClick={() => {
                setShowSignature(true);
                setSignature("");
              }}
              style={{
                color: `${
                  showSingnature ? "#383E45" : "rgba(56, 62, 69, 0.50)"
                }`,
                borderBottom: `${
                  showSingnature ? "1px solid green" : "1px solid transparent"
                }`,
                paddingBottom: "4px",
                cursor: "pointer",
              }}
            >
              Draw Signature
            </span>
            <span
              onClick={() => {
                setShowSignature(false);
                setSignature("");
              }}
              style={{
                color: `${
                  !showSingnature ? "#383E45" : "rgba(56, 62, 69, 0.50)"
                }`,
                borderBottom: `${
                  !showSingnature ? "1px solid green" : "1px solid transparent"
                }`,
                paddingBottom: "4px",
                cursor: "pointer",
              }}
            >
              Type Signature
            </span>
          </div>
          {showSingnature ? (
            <>
              <div className="type-signature-title ">
                Draw your signature below
              </div>
              <div className="type-signature-container2">
                <SignatureCanvas
                  canvasProps={{
                    width: 550,
                    height: 200,
                    className: "sigCanvas",
                  }}
                  ref={(data) => setSign(data)}
                />
                <button
                  className="btn btn-outline-primary btn-sm btn-clear mt-2"
                  style={{ fontFamily: "Inter" }}
                  onClick={handleClear}
                >
                  Clear
                </button>
              </div>
            </>
          ) : (
            <div style={{ width: "100%" }} className="mt-2">
              <div
                className="mb-4 w-100"
                style={{ display: "flex", flexDirection: "column" }}
              >
                <label
                  htmlFor="typeSignature"
                  className="form-label label-title"
                >
                  Entry your signature
                </label>
                <input
                  type="text"
                  className="form-control"
                  style={{ padding: "10px", height: "52px" }}
                  id="typeSignature"
                  placeholder="Name of entry"
                  autoComplete="off"
                  value={signature ? signature : ""}
                  onChange={(e) => {
                    setSignature(e.target.value);
                  }}
                />
              </div>
              <div className="editBorder-write-signature">
              <div className="draw-signature-container" id="signature" style={{ backgroundColor: "transparent", border: "none" }}>
                {signature}
              </div>
              </div>
            </div>
          )}

          <div
            className="step-card-line mb-1"
            style={{
              backgroundColor: "#0572D2",
              width: "89px",
              height: "1px",
            }}
          ></div>
          <div className="step-button-container mt-3">
            <button
              type="button"
              className="btn btn-primary button-steper"
              style={{ width: "100%" }}
              onClick={()=>{
                registerEntity();
              }}
            >
              Update
            </button>
          </div>
        </div>
      </div>
      <div className="esignature-footer">© Copyright {year} NDABee.</div>
    </div>
    <ToastContainer theme="colored" position="top-right" autoClose={2000} />
    </>
  );
};

export default EditYourSignature;
