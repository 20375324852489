import React, { useEffect, useState } from "react";
import {
  BASE_URL,
  HEADERS,
  SERVICES,
  SERVICES_NAME,
  VERSION,
} from "../../Constants/Auth";
import "./EditYourEntity.css";
import axios from "axios";
import { USER_ID } from "../../Constants/User";
import { useNavigate } from 'react-router-dom';
import { GetAccountType } from "../../Apis/Accounts/Models/GetAccountType";
import { useDispatch } from "react-redux";
import { hideLoading, showLoading } from "../../redux/features/alertSlice";
import { toast, ToastContainer } from "react-toastify";
import { GetByMember } from "../../Apis/Accounts/Models/GetByMember";
import { SaveEntity } from "../../Apis/Accounts/Models/SaveEntity";
import { setAcitveNav } from "../../redux/features/userSlice";

const EditYourEntity = () => {
  const baseURL = BASE_URL + SERVICES + "/" + VERSION + "/" + SERVICES_NAME;
  const [entityTypesList, setEntityTypesList] = useState([]);
  const [stateList, setStateList] = useState([]);
  const [entity, setEntity] = useState({
    nameEntry: "",
    type: "",
    state: "",
    city: "",
    state2: "",
    zipCode: "",
    street: "",
    latitude: "",
    longitude: "",
    country: "",
    addressState: "",
    accountId: ""
  });
  const currentId = USER_ID();
  const navigate = useNavigate();
  const year = new Date().getFullYear();
  const dispatch = useDispatch()

  // const getAccountTypes = async () => {
  //   try {
  //     const classRef = new GetAccountType();
  //     await classRef.List()
  //     let result = classRef.ResponseBody;
  //     console.log("Account types", result)
  //      if(result.EntityTypesList){
  //       setEntityTypesList(result.EntityTypesList);
  //      }
  //   } catch (error) {
  //     console.log("Error", error)
  //   }
  // };

  // const getStateList = () => {
  //   let config = {
  //     method: "get",
  //     maxBodyLength: Infinity,
  //     url: `${baseURL}/External/States/List`,
  //     headers: HEADERS(),
  //   };

  //   axios
  //     .request(config)
  //     .then((response) => {
  //       if (response.data.StatesList) {
  //         setStateList(response.data.StatesList);
  //         console.log("=>>", response.data.StatesList);
  //       }
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //     });
  // };


  const getMembersInfo = async () => {
    try {
      const classRef = new GetByMember();
      await classRef.List({
        MembersId: parseInt(currentId)
      })
      let result = classRef.ResponseBody;
      if(result.AccountInfo){
        const info = result.AccountInfo;
        setEntity({
          nameEntry: info.Name,
          type: info.EntityTypesId,
          state: info.StatesId,
          city: "",
          state2: "",
          zipCode: "",
          street: "",
          latitude: "",
          longitude: "",
          country: "",
          addressState: "",
          accountId: info.Id
        });
      }else{
        toast.warning("No data available")
      }

    } catch (error) {
      console.log("Error", error)
    }
  };

  const saveEditEntity = async () => {
    dispatch(showLoading())
    try {
      const classRef = new SaveEntity();
      await classRef.List({
     AccountsId: entity.accountId,
      AddressLocation: null,
      AddressStreet: null,
      AddressAppOrSuite: null,
      AddressCity: null,
      AddressState: null,
      AddressZipCode: null,
      AddressLongitude: null,
      AddressLatitude: null,
      EntityTypesId: entity.type,
      StatesId: entity.state,
      Name: entity.nameEntry,
      Description: null,
      })
      let result = classRef.ResponseBody;
      dispatch(hideLoading())
      toast.success("Your entity was updated successfully")
      if(result){
        dispatch(setAcitveNav("settings"))
        navigate("/Settings")
      }

    } catch (error) {
      dispatch(hideLoading())
      console.log("Error", error);
    }
    // const myHeaders = new Headers();
    // myHeaders.append("AppName", "DbBee");
    // myHeaders.append("Content-Type", "application/json");
    // myHeaders.append(
    //   "Authorization",
    //   "Bearer 14FE34B2-9547-43F5-A57C-F0DC7DE81AA9"
    // );

    // const raw = JSON.stringify({
    //   AccountsId: entity.accountId,
    //   AddressLocation: null,
    //   AddressStreet: null,
    //   AddressAppOrSuite: null,
    //   AddressCity: null,
    //   AddressState: null,
    //   AddressZipCode: null,
    //   AddressLongitude: null,
    //   AddressLatitude: null,
    //   EntityTypesId: entity.type,
    //   StatesId: entity.state,
    //   Name: entity.nameEntry,
    //   Description: null,
    // });

    // const requestOptions = {
    //   method: "PATCH",
    //   headers: myHeaders,
    //   body: raw,
    //   redirect: "follow",
    // };

    // fetch(
    //   "https://api.softsmartinc.com/Services/v2/Bee/Accounts/Save",
    //   requestOptions
    // )
    //   .then((response) => response.json())
    //   .then((result) => {
    //     navigate("/Settings")
    //     console.log("Your entity was updated successfully")
    //   })
    //   .catch((error) => console.error(error));
  };

  useEffect(() => {
    // getAccountTypes();
    // getStateList();
    getMembersInfo();
  }, []);

  return (
    <>
    <div className="edite-principal-container">
      <div></div>
      <div className="edite-card-container">
        <div className="step-card-container">
          <div className="step-card-title">Edit your Entity!</div>
          <div className="step-card-line"></div>
          <div
            className="mb-4 w-100"
            style={{ display: "flex", flexDirection: "column" }}
          >
            <label htmlFor="name-entry" className="form-label label-title" style={{lineHeight:"20px"}}>
              Name of Entry <br />
              <span className="input-subtitle">Enter the official name of your entity:</span>
            </label>
            <input
              type="text"
              className="form-control"
              style={{ padding: "10px", height: "52px" }}
              id="name-entry"
              placeholder="Name of entry"
              autoComplete="off"
              value={entity?.nameEntry}
              name="nameEntry"
              onChange={(e)=>{
                setEntity({ ...entity, ["nameEntry"]: e.target.value });
              }}
            />
          </div>
          <label htmlFor="name-entry" className="form-label label-title" style={{lineHeight:"20px",width:"100%"}}>
          Entity Name<br />
              <span className="input-subtitle">Enter the official name of your entity:</span>
            </label>
          <div className="step-card-radio-buttons">
            <div className="step-card-radio">
              <div className="step-card-radio1">
                {entityTypesList && entityTypesList.length > 0
                  ? entityTypesList.map((list, index) =>
                      index < 3 ? (
                        <div className={`form-check`} key={list.CodeId}>
                          <input
                            className="form-check-input"
                            type="radio"
                            name="type"
                            id={`type${list.CodeId}`}
                            value={list.CodeId}
                          />
                          <label
                            className="form-check-label"
                            htmlFor={`type${index}`}
                          >
                            {" "}
                            {list.Description}
                          </label>
                        </div>
                      ) : null
                    )
                  : null}
              </div>
              <div className="step-card-radio2">
                {entityTypesList && entityTypesList.length > 0
                  ? entityTypesList.map((list, index) =>
                      index >= 3 ? (
                        <div className={`form-check`} key={index}>
                          <input
                            className="form-check-input"
                            type="radio"
                            name="type"
                            id={`type${list.CodeId}`}
                            value={list.CodeId}
                          />
                          <label
                            className="form-check-label"
                            htmlFor={`type${index}`}
                          >
                            {" "}
                            {list.Description}
                          </label>
                        </div>
                      ) : null
                    )
                  : null}
              </div>
            </div>
          </div>
          <div
            className="mb-4 w-100 mt-4"
            style={{ display: "flex", flexDirection: "column" }}
          >
             <label htmlFor="name-entry" className="form-label label-title" style={{lineHeight:"20px"}}>
            State of Incorporation<br />
              <span className="input-subtitle">Select the state where your entity will be registered from the dropdown menu below:</span>
            </label>
            <select
              className="form-select"
              aria-label="Default select example"
              style={{ padding: "10px", height: "52px" }}
              value={entity.state} 
              onChange={(e) => {
                setEntity({ ...entity, state: parseInt(e.target.value) });
              }}
            >
              <option value="">State of Incorporation</option>
              {stateList && stateList.length > 0
                ? stateList.map((state, index) => (
                    <option key={index} value={state.CodeId}>
                      {state.Description}
                    </option>
                  ))
                : null}
            </select>
          </div>
          <div
            className="step-card-line"
            style={{ backgroundColor: "#0572D2", width: "89px", height: "1px" }}
          ></div>
          <div className="step-button-container">
            <button
              type="button"
              className="btn btn-primary"
              style={{ width: "100%" }}
              onClick={()=>{
                saveEditEntity();
              }}
            >
              Update
            </button>
          </div>
        </div>
      </div>
      <div className="edite-footer ">© Copyright {year} NDABee.</div>
    </div>
    <ToastContainer theme="colored" position="top-right" autoClose={2000} />
    </>
  );
};

export default EditYourEntity;
