import React, { useState } from "react";
import "./NdaTerms.css";

const NdaTerms = () => {
  const[align,setAlign] = useState("");
  const [size,setSize] = useState(20);
  const year = new Date().getFullYear();

  return (
    <div>
      <div className="term-fo-service-principal-container">
        <div className="termsnda-container">
        <div style={{display:"flex", justifyContent:"space-between"}}>
          <div
            className="btn-group"
            role="group"
            aria-label="Basic outlined example"
          >
            <button type="button" className="btn btn-outline-primary" onClick={()=>{setAlign("left")}}>
              Left
            </button>
            <button type="button" className="btn btn-outline-primary" onClick={()=>{setAlign("justify")}}>
              Justify
            </button>
            <button type="button" className="btn btn-outline-primary" onClick={()=>{setAlign("right")}}>
              Right
            </button>
          </div>
          <div
            className="btn-group"
            role="group"
            aria-label="Basic outlined example"
          >
            <button type="button" className="btn btn-outline-primary" onClick={() => setSize(prevSize => prevSize + 1)}>
              +
            </button>
            <div  className="btn btn-outline-primary">
              {size === "" ? "20" : size}
            </div>
            <button type="button" className="btn btn-outline-primary" onClick={() => setSize(prevSize => prevSize - 1)}>
              -
            </button>
          </div>
        </div>
          <h1 className="terms-title">Terms of NDA</h1>
          <div className="green-line-terms">
            <div className="green-line-term"></div>
          </div>
          <p className="terms-text mt-4" style={{textAlign:`${align}`,fontSize:`${size}px`}}>
            In order to pursue the mutual business between the parties and/or
            their respective aﬃliates (the “Transaction”), both Disclosing Party
            and the Receiving Party recognize that there is a need to disclose
            to one another certain information in respect of itself and/or its
            aﬃliates. All such information, delivered by or on behalf of
            Disclosing Party and/or its Representatives (as deﬁned below) to the
            “Receiving Party” and/or its Representatives, whether furnished
            before or after the date of this Agreement and regardless of the
            manner in which it is furnished, together with all analyses,
            compilations, studies or other documents or records prepared by the
            Receiving Party and/or its Representatives, to the extent such
            analyses, compilations, studies, documents or records contain,
            otherwise reﬂect, or are generated from such information, is
            referred to herein as “Conﬁdential Information.” In consideration of
            the opportunity to consider such Conﬁdential Information, both
            parties hereby agree as follows:
          </p>
          <div className="row p-2">
            <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <p className="terms-text" style={{textAlign:`${align}`,fontSize:`${size}px`}}>
                <span style={{ paddingRight: "60px" }}>1.</span>Conﬁdential
                Information will be used by the Receiving Party solely for the
                purpose of evaluating the Transaction. Conﬁdential Information
                will be kept strictly conﬁdential by the Receiving Party, except
                that Conﬁdential Information or any portion thereof may be
                disclosed to aﬃliates, directors, oﬃcers, employees, advisors,
                attorneys, agents, controlling persons, ﬁnancing sources or
                other Representatives (each, a “Representative”, and
                collectively, the “Representatives”) of the Receiving Party who
                need to know such Conﬁdential Information for the purpose of
                evaluating the Transaction and who agree to treat such
                Conﬁdential Information in accordance with the terms of this
                Agreement.
              </p>
              <p className="terms-text" style={{textAlign:`${align}`,fontSize:`${size}px`}}>
                <span style={{ paddingRight: "60px" }}>2.</span>The term
                “Conﬁdential Information” does not include information which (i)
                is or becomes generally available to the public other than as a
                result of the breach of the terms of this Agreement by the
                Receiving Party and/or any of its Representatives, (ii) is or
                has been independently acquired or developed by the Receiving
                Party and/or any of its Representatives without violating any of
                the terms of this Agreement, (iii) was within the Receiving
                Party’s and/or any of its Representatives’ possession prior to
                it being furnished to the Receiving Party and/or any of its
                Representatives by or on behalf of the Disclosing Party pursuant
                to the terms hereof or (iv) is received from a source other than
                the Disclosing Party and/or any of its Representatives; provided
                that, in the case of (iii) and (iv) above, the source of such
                information was not known by the Receiving Party to be bound by
                a conﬁdentiality obligation to the Disclosing Party or any other
                party with respect to such information.
              </p>
              <p className="terms-text" style={{textAlign:`${align}`,fontSize:`${size}px`}}>
                <span style={{ paddingRight: "60px" }}>3.</span>In the event
                that the Receiving Party or any of its Representatives receives
                demand or request to disclose all or any part of the Disclosing
                Party’s Conﬁdential Information under the terms of a subpoena or
                order issued by a court of competent jurisdiction or under a
                civil investigative demand or similar process, (i) if legally
                permissible, the Receiving Party agrees to promptly notify the
                Disclosing Party of the existence, terms and circumstances
                surrounding such a demand or request and (ii) if the Receiving
                Party or its applicable Representative is in the opinion of its
                counsel compelled to disclose all or a portion of the Disclosing
                Party’s Conﬁdential Information, the Receiving Party or its
                applicable Representative may disclose that Conﬁdential
                Information that its counsel advises that it is compelled to
                disclose and will exercise reasonable eﬀorts to obtain assurance
                that conﬁdential treatment will be accorded to the Conﬁdential
                Information that is being so disclosed.
              </p>
              <p className="terms-text" style={{textAlign:`${align}`,fontSize:`${size}px`}}>
                <span style={{ paddingRight: "60px" }}>4.</span>Unless otherwise
                required by law, or unless otherwise provided in a ﬁnal
                deﬁnitive agreement regarding the Transaction when, as and if
                executed, both parties and their respective Representatives will
                not, without the prior written consent of the other party,
                disclose to any person (other than Representatives of the
                parties hereto who need to know such information for the purpose
                of evaluating the Transaction and who agree to treat such
                information in accordance with the terms of this Agreement): (i)
                that discussions or negotiations are taking place concerning the
                Transaction, or (ii) any of the terms or conditions of the
                Transaction. Neither this Agreement nor the disclosure or
                receipt of Conﬁdential Information shall be construed as
                creating any obligation of a party to furnish Conﬁdential
                Information to the other party or to enter into any agreement or
                relationship with the other party.
              </p>
              <p className="terms-text" style={{textAlign:`${align}`,fontSize:`${size}px`}}>
                <span style={{ paddingRight: "60px" }}>5.</span>Nothing in this
                Agreement shall divest the Disclosing Party of any of its right,
                title or interest in or to any of its Conﬁdential Information.
                Within ten (10) days after receiving a request by the Disclosing
                Party for the destruction of Conﬁdential Information, the
                Receiving Party and its Representatives shall destroy all
                Conﬁdential Information furnished to the Receiving Party and/or
                any of its Representatives by or on behalf of the Disclosing
                Party.
              </p>
            </div>
            <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <p className="terms-text" style={{textAlign:`${align}`,fontSize:`${size}px`}}>
                Notwithstanding the foregoing, the Receiving Party may retain
                the Conﬁdential Information, provided that such Conﬁdential
                Information is retained subject to the conﬁdentiality provisions
                contained herein for so long as it is retained by the Receiving
                Party, irrespective of the term of this Agreement, where and to
                the extent the Conﬁdential Information is: (a) retained in
                back-up systems in accordance with its automated security and/or
                disaster recovery procedures as in eﬀect from time to time; (b)
                incorporated in a limited manner into analyses or documents
                prepared by the Receiving Party; or (c) retained by counsel for
                regulatory compliance, or for use in pursuing, defending, or
                resolving a dispute under this Agreement.
              </p>
              <p className="terms-text" style={{textAlign:`${align}`,fontSize:`${size}px`}}>
                <span style={{ paddingRight: "60px" }}>6.</span>The Receiving
                Party acknowledges and agrees that neither the Disclosing Party
                nor any of its Representatives is making any representation or
                warranty as to the accuracy or completeness of any of the
                information furnished hereunder to the Receiving Party or any of
                its Representatives and each of the Receiving Party and the
                Disclosing Party further acknowledges and agrees that no party
                has any obligation to the other party or any of its
                Representatives to authorize or pursue with the other party the
                Transaction.
              </p>
              <p className="terms-text" style={{textAlign:`${align}`,fontSize:`${size}px`}}>
                <span style={{ paddingRight: "60px" }}>7.</span>Both parties
                agree that money damages may not be a suﬃcient remedy for any
                breach of the terms of this Agreement by the Receiving Party or
                any of its Representatives, and that, in addition to all other
                remedies at law or in equity to which the Disclosing Party may
                be entitled, the Disclosing Party may be entitled to seek
                speciﬁc performance and injunctive or other equitable relief as
                a remedy for any such breach.
              </p>
              <p className="terms-text" style={{textAlign:`${align}`,fontSize:`${size}px`}}>
                <span style={{ paddingRight: "60px" }}>8.</span>If it is found
                in a ﬁnal judgment by a court of competent jurisdiction (not
                subject to further appeal) that any term or provision hereof is
                invalid or unenforceable, it will be ineﬀective only to the
                extent of the invalidity, so that the remainder of the provision
                and Agreement will continue in full force and eﬀect.
              </p>
              <p className="terms-text" style={{textAlign:`${align}`,fontSize:`${size}px`}}>
                <span style={{ paddingRight: "60px" }}>9.</span>This Agreement
                shall not be construed to limit the Disclosing Party’s, the
                Receiving Party’s, or any of their respective Representatives’
                right to independently develop or acquire products, services, or
                technology without use of the other party's Conﬁdential
                Information.
              </p>
              <p className="terms-text" style={{textAlign:`${align}`,fontSize:`${size}px`}}>
                <span style={{ paddingRight: "60px" }}>10.</span>The validity
                and interpretation of this Agreement shall be governed by, and
                construed and enforced in accordance with, the laws of the State
                of North Carolina applicable to agreements made and to be fully
                performed therein (without regard to its rules with respect to
                conﬂicts of law).
              </p>
              <p className="terms-text" style={{textAlign:`${align}`,fontSize:`${size}px`}}>
                <span style={{ paddingRight: "60px" }}>11.</span>Neither party
                hereto shall assign in whole or in part its rights or
                obligations under this Agreement without the express written
                consent of the other party. This Agreement shall be binding upon
                and shall inure to the beneﬁt of each party’s successors and
                permitted assigns.
              </p>
              <p className="terms-text" style={{textAlign:`${align}`,fontSize:`${size}px`}}>
                <span style={{ paddingRight: "60px" }}>12.</span>This Agreement
                represents the parties’ entire understanding regarding the
                subject matter and with respect to the subject matter,
                supersedes all written or oral agreements previously made by or
                on behalf of the parties. This Agreement may not be modiﬁed
                except by a written agreement signed by both parties.
              </p>
              <p className="terms-text" style={{textAlign:`${align}`,fontSize:`${size}px`}}>
                <span style={{ paddingRight: "60px" }}>13.</span>For the
                convenience of the parties, any number of counterparts of this
                Agreement may be executed by the parties hereto. Each such
                counterpart shall be, and shall be deemed to be, an original
                instrument, and all such counterparts taken together shall
                constitute one and the same agreement.
              </p>
              <p className="terms-text" style={{textAlign:`${align}`,fontSize:`${size}px`}}>
                <span style={{ paddingRight: "60px" }}>14.</span>The term of
                this Agreement shall be three (3) years from the date hereof
              </p>
            </div>
          </div>
          <p className="terms-text mt-3 text-center" style={{textAlign:`${align}`,fontSize:`${size}px`}}>
            IN WITNESS WHEREOF, this Agreement has been duly executed on the
            date ﬁrst written above.
          </p>
        </div>
        <div className="footer-terms">© Copyright {year} NDABee.</div>
      </div>
    </div>
  );
};

export default NdaTerms;
