import React from "react";
import logo from "../../images/logo2.png";
import fileNda from "../../images/ndaLock.png";
import "./SendNDA.css";
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import {Link} from "react-router-dom"
import { setAcitveNav } from "../../redux/features/userSlice";
import { useDispatch } from "react-redux";

const SendNDA = () => {
    const navigate = useNavigate();
    const {Id} = useParams();
    const year = new Date().getFullYear();
    const dispatch = useDispatch();
  return (
    <div className="sendNda-principal-container">
      <div className="sendNda-logo-container">
        <img src={logo} alt="confidentialbee-logo" 
        onClick={()=>{
          dispatch(setAcitveNav("home"))
            navigate("/")
        }}
        style={{cursor:"pointer"}}
        />
      </div>
      {/*main part*/}
      <div className="row w-100 send-nda-container">
        <div className="sendNda-title">Send NDA</div>
        <div style={{width:"500px", display:"flex",justifyContent:"center"}}>
        <div className="greenlineNda"></div>
        </div>
        <div className="send-nda-image-container">
          <img src={fileNda} alt="file-nda" />
          <div className="send-nda-select">
            <div className="send-nda-select-title">Note</div>
            <div className="send-nda-select-text">
              You acknowledge that the provided NDA document meets your standards for an NDA doucment.
            </div>
            <div
              className="d-flex justify-content-center"
              style={{ width: "100%" }}
            >
              <button
                type="button"
                className="btn btn-light"
                style={{ width: "80%" }}
                onClick={()=>{
                    navigate(`/nda/${Id}`)
                }}
              >
                I Agree to Terms & Conditions
              </button>
            </div>
          </div>
        </div>
        <div className="send-nda-show-page">
              <input
                className="form-check-input me-1"
                type="checkbox"
                value=""
                id="firstCheckbox"
                style={{marginBottom:"27px"}}
              />
              <label className="form-check-label label-input" htmlFor="firstCheckbox">
              By selecting  the checkbox, you agree to the <Link to="/terms-and-conditions" target="_blank" style={{color:"white", fontWeight:"600"}}>Terms and Conditions</Link><br />
              each time you send an NDA, and this screen will no longer bee displayed.
              </label>
        </div>
      </div>

      {/*footer*/}
      <div className="nda-footer">© Copyright {year} NDABee.</div>
    </div>
  );
};

export default SendNDA;
