const ISDEV = window.location.hostname === "localhost" || window.location.hostname.includes(".dev");
export const BASE_URL = ISDEV ? "https://api.softsmartinc.com/" : "https://api.softsmartinc.com/";

export const SERVICES_NAME = "Bee";
export const SERVICES = "Services";
export const WIDGET = "Widget";
export const VERSION = "v4";

export const SERVICE_AUTH = `${SERVICES}/${VERSION}/Bee/Members/Authentication/Authenticate`;
export const SERVICE_DATA = `${SERVICES}/${VERSION}/Data`;
export const SERVICE_BEE = `${SERVICES}/${VERSION}/Bee`;
export const SERVICE_SAVE = `${SERVICES}/${VERSION}/Data/Save`;
export const SERVICE_FILE = `${SERVICES}/${VERSION}/${WIDGET}/Files`;
export const SERVICE_NOTE = `${SERVICES}/${VERSION}/${WIDGET}/Notes`;
export const SERVICE_EMAIL = `${SERVICES}/${VERSION}/${WIDGET}/Email`;

export const APP_NAME = "DbBee";
export const APP_TOKEN = "14FE34B2-9547-43F5-A57C-F0DC7DE81AA9";
export const APP_ENCRYPT = false;
export const MEDIA_URL = `${BASE_URL}${SERVICE_FILE}/Download/${APP_NAME}`;

export const HEADERS = () => {
    return {
        'Content-Type': 'application/json',    
        'AppName': APP_NAME, 
        'Authorization': `Bearer ${APP_TOKEN}`,
        "AppEncrypt": APP_ENCRYPT
    };
}

export const HEADERS_FORM_DATA = (AppEncrypt) => {
    return {
        "Content-Type": "multipart/form-data",
        'Authorization': `Bearer ${APP_TOKEN}`,
        "AppName": APP_NAME,
        "AppEncrypt": AppEncrypt
    };
}