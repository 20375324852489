import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import logo from "../../images/logo2.png";
import beeLogo from "../../images/beelogo.png";
import "./Waitlist.css";
import "react-phone-input-2/lib/bootstrap.css";
import PhoneInput from "react-phone-input-2";
import TimerCountDown from "../../Components/TimerCountDown/TimerCountDown";
import { BASE_URL, HEADERS, SERVICE_SAVE } from "../../Constants/Auth";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Waitlist = () => {
  const baseUrl = BASE_URL + SERVICE_SAVE;
  const navigate = useNavigate();
  const [phoneNumber, setPhoneNumber] = useState("");
  const [dialCode, setDialCode] = useState("");
  const [valid, setValid] = useState(true);
  const [formulario, setFormulario] = useState({
    FirstName: "",
    LastName: "",
    Email: "",
    PhoneCountryCode: "",
    PhoneNo: "",
  });

  const objetiveTime = new Date("Feb 21, 2024").getTime();

  const resetFrom = () => {
    setFormulario({
      FirstName: "",
      LastName: "",
      Email: "",
      PhoneCountryCode: "",
      PhoneNo: "",
    });
    setPhoneNumber("");
  };

  const handlePhoneChange = (value, country, e, formattedValue) => {
    setPhoneNumber(formattedValue);
    setFormulario({ ...formulario, PhoneNo: formattedValue });
    setFormulario({ ...formulario, PhoneCountryCode: `+${country.dialCode}` });
    setDialCode("+" + country.dialCode);
    setValid(validatePhoneNumber(formattedValue));
  };

  const validatePhoneNumber = (phoneNumber) => {
    const phoneNumberPattern = /^\+?[1-9]\d{1,14}$/;

    return phoneNumberPattern.test(phoneNumber);
  };

  const handleChange2 = (e) => {
    const { name, value } = e.target;
    setFormulario({ ...formulario, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const res = await axios.patch(
        baseUrl,
        {
          Model: "Waitlist",
          Extension: null,
          Id: 0,
          Params: {
            FirstName: formulario.FirstName,
            LastName: formulario.LastName,
            Email: formulario.Email,
            PhoneCountryCode: formulario.PhoneCountryCode,
            PhoneNo: phoneNumber,
            Comments: `A request was submit by ${formulario.FirstName} ${formulario.LastName}, with email ${formulario.Email}`,
          },
        },
        {
          headers: HEADERS(),
        }
      );

      if (res.data.SaveInfo) {
        resetFrom();
        toast.success("Success!");
      } else {
        toast.warning("Wrong request!");
      }
    } catch (error) {
      alert("Error");
    }
  };

  const homepage = () => {
    navigate("/");
  };
  return (
    <div className="homepage-container2">
      <div className="contenedor">
        {/*Logo container */}
        {/* <div className="row mt-4">
          <div className="col-12 mt-3">
            <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12 logo-container mobile-marginTop">
              <img src={logo} alt="logo" />
            </div>
          </div>
        </div> */}

        <div className="row">
          <div className="col-xxl-6 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
            {/*Logo container */}

            <div className="row mt-4">
              <div className="col-12 mt-3">
                <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12 logo-container mobile-marginTop">
                  <img
                    src={logo}
                    alt="logo"
                    onClick={homepage}
                    style={{ cursor: "pointer" }}
                  />
                </div>
              </div>
            </div>

            {/*Main container */}
            <div className="title-container mt-280">
              <h1 className="title2">
                Be the first to Benefit for <br />
                the First time from a Simple <br />
                Solution to a Difficult <br />
                Problem
              </h1>
            </div>
            <div className="title-container mt-4">
              <p>
                Please enter your information and join our list <br /> to be
                among the first to receive our <br />
                application:
              </p>
              <ul>
                <li>Send NDA's Fast and Easy.</li>
                <li>
                  No More Figuring Out how to get an NDA document sent to a
                  prospective Partner
                </li>
                <li>Use Our Standard Format NDA or your Own.</li>
              </ul>
            </div>

            <div className="objetive-timer">
              <TimerCountDown time={objetiveTime} />
            </div>
          </div>

          <div className="col-xxl-6 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 second-col">
            <div className="form-container">
              <div className="bee-logo-container">
                <img src={beeLogo} alt="bee logo" />
                <div className="form-title">Wait List</div>
              </div>
              <form onSubmit={handleSubmit}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginTop: "45px",
                  }}
                >
                  <div
                    className="mb-4"
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      width: "48%",
                    }}
                  >
                    <label
                      htmlFor="exampleFormControlInput1"
                      className="form-label label-title"
                    >
                      First Name
                    </label>
                    <input
                      type="text"
                      autoComplete="off"
                      value={formulario.FirstName}
                      name="FirstName"
                      onChange={handleChange2}
                      className="form-control"
                      style={{ padding: "10px", height: "52px" }}
                      id="exampleFormControlInput1"
                      placeholder="First Name"
                      required
                    />
                  </div>

                  <div
                    className="mb-4"
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      width: "48%",
                    }}
                  >
                    <label
                      htmlFor="exampleFormControlInput2"
                      className="form-label label-title"
                    >
                      Last Name
                    </label>
                    <input
                      type="text"
                      autoComplete="off"
                      value={formulario.LastName}
                      name="LastName"
                      onChange={handleChange2}
                      className="form-control"
                      style={{ padding: "10px", height: "52px" }}
                      id="exampleFormControlInput2"
                      placeholder="Last Name"
                      required
                    />
                  </div>
                </div>

                <div
                  className="mb-4"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    width: "100%",
                  }}
                >
                  <label
                    htmlFor="exampleFormControlInput3"
                    className="form-label label-title"
                  >
                    Phone Number
                  </label>
                  <PhoneInput
                    country={"us"}
                    required
                    value={phoneNumber}
                    onChange={handlePhoneChange}
                    inputProps={{
                      style: {
                        height: "52px",
                        width: "100%",
                        border: "1px solid lightgray",
                      },
                      required: true,
                    }}
                  />
                </div>

                <div
                  className="mb-4"
                  style={{ display: "flex", flexDirection: "column" }}
                >
                  <label
                    htmlFor="exampleFormControlInput4"
                    className="form-label label-title"
                  >
                    Email
                  </label>
                  <input
                    type="email"
                    className="form-control"
                    value={formulario.Email}
                    name="Email"
                    onChange={handleChange2}
                    style={{ padding: "10px", height: "52px" }}
                    id="exampleFormControlInput4"
                    placeholder="Email"
                    required
                  />
                </div>
                <div className="mb-4 message">
                  Be one of the first 1000 in the wait list and you will get
                  Confidential Bee (Basic) for Free!
                </div>
                <div
                  className="col-12"
                  style={{ display: "flex", justifyContent: "center" }}
                >
                  <div className="blue-line"></div>
                </div>
                <div className="col-12">
                  <button
                    type="submit"
                    className="btn btn-primary w-100 mb-3"
                    style={{ height: "50px" }}
                  >
                    Submit
                  </button>
                </div>
                <div
                  className="col-12"
                  style={{ display: "flex", justifyContent: "center" }}
                >
                  <div className="blue-line"></div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer theme="colored" position="top-right" autoClose={2000} />
    </div>
  );
};

export default Waitlist;
