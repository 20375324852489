import React from "react";
import logo from "../../images/logo2.png";
import deleteData from "../../images/deleteAccount.png";
import elipse from "../../images/elipse.png";
import "./RemoveAccount.css";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setAcitveNav } from "../../redux/features/userSlice";

const RemoveAccount = () => {
  const navigate = useNavigate();
  const year = new Date().getFullYear();
  const dispatch = useDispatch();
  return (
    <div className="delete-principal-container">
      <div className="delete-logo">
        <img src={logo} alt="logo" onClick={()=>{
           dispatch(setAcitveNav("home"))
          navigate("/")}
          } style={{cursor:"pointer"}}/>
      </div>
      <div className="delete-main-container">
        {/* <div className="delete-img-container">
          <img
            src={deleteData}
            alt="delete-data"
            width="250px"
            height="250px"
          />
          <img
            src={elipse}
            alt="delete-data"
            width="360px"
            style={{ marginTop: "-30px" }}
          />
        </div> */}
        <div className="delete-title" style={{marginTop:"0px"}}>We're sorry to see you go!</div>
        <div className="white-line-container mt-3">
          <div className="white-line"></div>
        </div>

        <div className="delete-text">
          If you wish to remove your account from ConfidentialBee, <br /> please
          follow the steps below:
        </div>

        <div className="white-line-container mb-1">
          <div className="white-line"></div>
        </div>

        <div className="buttons-delete-cont">
          <div className="card-remove-container mt-1">
          <div className="remove-text">
            <span className="remove-title">Log in:</span> Log in to your ConfidentialBee account using
            your username and password.
          </div>
          <div className="remove-text">
            <span className="remove-title">Access Account Settings:</span> Once logged in, navigate to
            your account settings. This can usually be found by clicking on your
            profile icon or name.
          </div>
          <div className="remove-text">
            <span className="remove-title">Find Account Removal Option:</span> Look for the option to remove or delete your account. This may be labeled as "Remove Account," "Delete Account," or something similar.
          </div>
          <div className="remove-text">
            <span className="remove-title">Follow Prompts:</span> Follow the prompts or instructions provided to initiate the account removal process. You may be asked to confirm your decision or provide a reason for removing your account.
          </div>
          <div className="remove-text">
            <span className="remove-title">Confirmation:</span> Once you have completed the steps to remove your account, you should receive a confirmation message indicating that your account has been successfully removed.
          </div>
          <div className="remove-text">
          Please note that removing your account is irreversible and will result in the permanent deletion of all account data, including any saved documents or information associated with your account.
          </div>
          <div className="remove-text">
          If you have any questions or encounter any issues while attempting to remove your account, please contact our support team at <a href="mailto:info@softsmart.com" style={{color:"#0445a5"}}>info@softsmart.com</a> for assistance.
          </div>
          <div className="remove-text d-flex justify-content-center mt-1">
          Thank you for using ConfidentialBee.
          </div>

          </div>
        </div>
      </div>
      <div className="delete-footer">© Copyright {year} NDABee.</div>
    </div>
  );
};

export default RemoveAccount;
