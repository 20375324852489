import React, { useEffect, useState } from "react";
import arrow from "../../images/arrow.png";
import Form from "react-bootstrap/Form";
import "./Settings.css";
import { useNavigate } from "react-router-dom";
import { USER_ID } from "../../Constants/User";
import { Get } from "../../Apis/Members/Models/Get";
import InfoModal from "../../Components/InfoModal/InfoModal";
import notification from "../../images/notification-logo.png";
import { SaveNotifications } from "../../Apis/Members/Models/SaveNotifications";
import { useDispatch } from "react-redux";
import { setAcitveNav } from "../../redux/features/userSlice";

const Settings = () => {
  const [fconected, setFconected] = useState(false);
  const [gconected, setGconected] = useState(true);
  const [lconected, setLconected] = useState(false);
  const [aconected, setAconected] = useState(false);
  const [userInfo, setUserInfo] = useState();
  const [notificationUpdate, setNotificationUpdate] = useState(false)
  const [permissionStatus, setPermissionStatus] = useState(null);
  const [show,setShow] = useState(false);
  const [show2,setShow2] = useState(false);
  
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const currentId = USER_ID();
  const year = new Date().getFullYear();

  const logout = () => {
    localStorage.clear();
    window.location.href = "/";
  };
  
  const handleClose = () => {
    setShow(false)
  }

  const handleClose2 = () => {
    setShow2(false)
  }


  const getUserInfo = async () => {
    try {
      const classRef = new Get()
      await classRef.List({MembersId: parseInt(currentId)})
      let result = classRef.ResponseBody;
  
      if(result.MemberInfo){
        setUserInfo(result)
        setNotificationUpdate(result.MemberInfo.SettingsNotifications)
      }
    } catch (error) {
      console.log("Error",error)
    }
   
  };

  const notifications = async (boolean) => {
    try {
      const classRef = new SaveNotifications();
      await classRef.List({
        MembersId: parseInt(currentId),
        SettingsFaceId: false,
        SettingsNotifications: boolean,
      })
      let result = classRef.ResponseBody;

      if(result.MemberInfo.SettingsNotifications){
        setShow(true)
      } else{
        setShow2(true)
      }

    } catch (error) {
      console.log("Error",error)
    }
  };

  useEffect(() => {
    dispatch(setAcitveNav("settings"))
    getUserInfo();
  }, []);

  return (
    <>
    <div className="settings-principal-container">
      <div></div>
      <div className="main-settings-container">
        <div className="settings-title">Settings</div>
        <div className="settings-greenline-container mt-2 mb-3">
          <div className="settings-greenline"></div>
        </div>
        <div className="account-settings">
          <div className="settings-option-title">Account Settings</div>
          <div
            className="card-option-settings"
            onClick={() => {
              navigate("/edit-info");
            }}
          >
            <div>Edit Profile</div>
            <img src={arrow} alt="arrow" />
          </div>
          <div
            className="card-option-settings boder-t"
            onClick={() => {
              navigate("/edit-password");
            }}
          >
            <div>Edit Password</div>
            <img src={arrow} alt="arrow" />
          </div>
          <div
            className="card-option-settings boder-t"
            onClick={() => {
              navigate("/edit-avatar");
            }}
          >
            <div>Edit Avatar</div>
            <img src={arrow} alt="arrow" />
          </div>
          <div
            className="card-option-settings boder-t"
            onClick={() => {
              navigate(`/edit-entity`);
            }}
          >
            <div>Setup your Entity!</div>
            <img src={arrow} alt="arrow" />
          </div>
        </div>
        <div className="settings-greenline-container mt- mb-1">
          <div
            className="settings-greenline"
            style={{ width: "80%", height: "1px" }}
          ></div>
        </div>
        <div className="preferences-settings ">
          <div className="settings-option-title">Preferences</div>
          <div className="card-option-settings">
            <div>Notifications</div>
            <div className="form-check form-switch">
              <input
                className="form-check-input"
                type="checkbox"
                role="switch"
                id="flexSwitchCheckDefault"
                checked={notificationUpdate}
                onChange={()=>{
                  if(!notificationUpdate){
                    setNotificationUpdate(true)
                    notifications(true)
                  } else{
                    setNotificationUpdate(false)
                    notifications(false)
                  }
                }}
              />
            </div>
          </div>
          {/* <div className="card-option-settings boder-t">
            <div>Face ID</div>
            <div className="form-check form-switch">
              <input
                className="form-check-input"
                type="checkbox"
                role="switch"
                id="flexSwitchCheckDefault2"
                disabled
              />
            </div>
          </div> */}
        </div>
        <div className="settings-greenline-container mt-2">
          <div
            className="settings-greenline"
            style={{ width: "80%", height: "1px" }}
          ></div>
        </div>
        <div className="socialmedia-settings ">
          <div className="settings-option-title">Social Media</div>
          <div className="card-option-settings">
            <div>Facebook Account</div>
            <div className="socialmedia-container">
              <span
                className="socialmedia-text"
                style={{ color: `${fconected ? "color: #06B576" : "red"}` }}
              >
                {fconected ? "Connected" : "Not Connected"}
              </span>
              <img src={arrow} alt="arrow" />
            </div>
          </div>
          <div className="card-option-settings boder-t">
            <div>Gmail Account</div>
            <div className="socialmedia-container">
              <span
                className="socialmedia-text"
                style={{ color: `${fconected ? "color: #06B576" : "red"}` }}
              >
                {fconected ? "Connected" : "Not Connected"}
              </span>
              <img src={arrow} alt="arrow" />
            </div>
          </div>
          <div className="card-option-settings boder-t">
            <div>Linkedin Account</div>
            <div className="socialmedia-container">
              <span
                className="socialmedia-text"
                style={{ color: `${fconected ? "color: #06B576" : "red"}` }}
              >
                {fconected ? "Connected" : "Not Connected"}
              </span>
              <img src={arrow} alt="arrow" />
            </div>
          </div>
          <div className="card-option-settings boder-t">
            <div>Apple Account</div>
            <div className="socialmedia-container">
              <span
                className="socialmedia-text"
                style={{ color: `${fconected ? "color: #06B576" : "red"}` }}
              >
                {fconected ? "Connected" : "Not Connected"}
              </span>
              <img src={arrow} alt="arrow" />
            </div>
          </div>
        </div>
        <div className="settings-greenline-container mt-2 ">
          <div
            className="settings-greenline"
            style={{ width: "80%", height: "1px" }}
          ></div>
        </div>
        <div className="desactive-settings ">
          <div className="settings-option-title">Deactive Account</div>
          {/* <div
            className="card-option-settings"
            onClick={() => {
              navigate("/delete-data");
            }}
          >
            <div>Delete Data</div>
            <img src={arrow} alt="arrow" />
          </div> */}
          <div
            className="card-option-settings boder-t"
            onClick={() => {
              navigate("/delete-account");
            }}
          >
            <div>Delete Account</div>
            <img src={arrow} alt="arrow" />
          </div>
        </div>
        <div className="settings-greenline-container mt-2">
          <div
            className="settings-greenline"
            style={{ width: "80%", height: "1px" }}
          ></div>
        </div>
        <div className="logout-settings mt-2 ">
          <div
            className="card-option-settings"
            onClick={() => {
              logout();
            }}
          >
            <div>Logout</div>
            <img src={arrow} alt="arrow" />
          </div>
        </div>
      </div>
      <div className="footer-settings">© Copyright {year} NDABee.</div>
    </div>
    <InfoModal
      notificationImage={notification}
      notificationTitle="Notification"
      show={show}
      handleClose={handleClose}
      notificationMessage={
        <div>
          To receive notifications, please ensure that notifications
          <br />
          are enabled in your browser settings.
        </div>
      }
    />

    <InfoModal
      notificationImage={notification}
      notificationTitle="Notification"
      show={show2}
      handleClose={handleClose2}
      notificationMessage={
        <div>
         To disable notifications, please desactivate 
          <br />
          them in your browser settings.
        </div>
      }
    />
    </>
  );
};

export default Settings;
