import React, { useState } from "react";
import logo from "../../images/logo.png";
import appstore from "../../images/appstore.png";
import playstore from "../../images/googleplay.png";
import appstore2 from "../../images/appstore-black.png";
import playstore2 from "../../images/playstore-black.png";
import phone1 from "../../images/Asset3.png";
import { NavLink, useNavigate, Link} from "react-router-dom";
import menu from "../../images/menu.png"
import close from "../../images/close.png"
import background from "../../images/WebsiteConfidencialBeev1.png";
import "./Homepage.css";
import { FaFacebookF } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import { AiFillInstagram } from "react-icons/ai";
import { FaLinkedinIn } from "react-icons/fa6";

const Homepage = () => {
  const navigate = useNavigate();

  const waitList = () => {
    navigate("/wait-list");
  };

  const login = () => {
    navigate("/login");
  };

  const [isOpen, setIsOpen] = useState(false);

  const toggleDrawer = () => {
    setIsOpen(!isOpen);
  };
  return (
    <>
      <div className="homepage-container">
        {/*logo and menu container*/}
        <div className="menu-logo-container">
          <div className="logo-home-container">
            <img src={logo} alt="logo" />
          </div>
          <div className="menu-home-container">
          <div className="web-menu">
            <NavLink to="/privacy-policy">PRIVACY POLICY</NavLink>
            <NavLink to="/terms-and-conditions">TERMS AND CONDITIONS</NavLink>
            <NavLink to="/remove-account">DISCLAIMER</NavLink>
           
            <NavLink to="/wait-list">
            <div className="box-waitlist">
             WAIT LIST
            </div>
            </NavLink>
            
            <NavLink to="/login">
            <div className="box-login">
              Login
              </div>
            </NavLink>
          </div>
          <div className="mobile-menu">
            <div onClick={toggleDrawer}>
              <img src={menu} alt="menu" className="img-menu"/>
            </div>
            <div className={`drawer ${isOpen ? "open" : ""}`}>
             <div className="header-menu">
              <div></div>
              <div onClick={toggleDrawer} className="closeBtn">
                <img src={close} alt="close" />
              </div>
             </div>
             <div className="drawer-menu">
             <NavLink to="/privacy-policy">PRIVACY POLICY</NavLink>
            <NavLink to="/terms-and-conditions">TERMS AND CONDITIONS</NavLink>
            <NavLink to="/remove-account">DISCLAIMER</NavLink>
            <NavLink to="/wait-list">WAIT LIST</NavLink>
            <NavLink to="/login">LOGIN</NavLink>
             </div>
            </div>
          </div>
          </div>
        </div>
        {/*Main content*/}
        <div className="main-homepage-content">
          <div className="main-homepage-image">
            <img src={phone1} alt="iphone-phone" />
          </div>
          <div className="main-homepage-title">
            <div className="main-home-principal-title1">Welcome to ConfidentialBee:</div>
            <div className="main-home-principal-title2"> Your Gateway to Secure Document</div>
            <div className="main-home-principal-title3">Management</div>
            <p>ConfidentialBee is your ultimate solution for secure document management. Whether you're a professional, a business owner, or an individual seeking to safeguard your sensitive information, ConfidentialBee has you covered. Our platform offers advanced encryption technology, seamless document sharing, and digital signature capabilities, all in one easy-to-use interface.
            <br /><br />
            Join our community today to experience the peace of mind that comes with knowing your confidential documents are protected. Whether you're on the go or working from home, ConfidentialBee ensures that your privacy remains intact.
            </p>
            
            <h2>Download the app now!</h2>
            <div className="store-images">
              <Link to="https://apps.apple.com/ca/app/confidential-bee/id6472225083" target="_blank">
              <img src={appstore} alt="appstore" />
              </Link>
              <Link to="https://play.google.com/store/apps/details?id=com.softsmart.confidentialbee&hl=es&gl=US" target="_blank">
              <img src={playstore} alt="playstore" />
              </Link>
            
            </div>
            <div className="homepage-social-media">
              <Link to="https://www.facebook.com" target="_blank">
              <FaFacebookF size={24} color="white"/>
              </Link>

              <Link to="https://x.com/?lang=en" target="_blank">
              <FaXTwitter size={25} color="white"/>
              </Link>

              <Link to="https://www.instagram.com/" target="_blank">
              <AiFillInstagram size={25} color="white"/>
              </Link>

              <Link to="https://www.linkedin.com" target="_blank">
              <FaLinkedinIn size={25} color="white"/>
              </Link>

            </div>
          </div>
        </div>
        {/*footer content*/}
        <div className="footer-homepage-content">
        © Copyright 2024 ConfidencialBee.
        </div>
      </div>
    </>
  );
};

export default Homepage;
