import React from "react";
import logo from "../../images/logo2.png";
import deleteData from "../../images/deleteAccount.png";
import elipse from "../../images/elipse.png";
import "./DeleteAccount.css";
import { useNavigate } from "react-router-dom";
import { USER_ID } from "../../Constants/User";
import { DeleteAccountInfo } from '../../Apis/Members/Models/DeleteAccountInfo';

const DeleteAccount = () => {
  const naviagte = useNavigate();
  const currentUserId = USER_ID();
  const year = new Date().getFullYear();

  const logout = () => {
    localStorage.clear();
    window.location.href = "/";
  };

  const deleteAccount = async () => {
    try {
      const classRef = new DeleteAccountInfo();
      await classRef.List({
        MembersId: parseInt(currentUserId)
      })
      let result = classRef.ResponseBody;
      if(result.Status === "Successful!"){
        logout();
      }
    } catch (error) {
      console.log("Error",error)
    }
  };
  return (
    <div className="delete-principal-container">
      <div className="delete-logo">
        <img src={logo} alt="logo" />
      </div>
      <div className="delete-main-container">
        <div className="delete-img-container">
          <img
            src={deleteData}
            alt="delete-data"
            width="350px"
            height="350px"
          />
          <img
            src={elipse}
            alt="delete-data"
            width="360px"
            style={{ marginTop: "-30px" }}
          />
        </div>
        <div className="delete-title">Delete Account?</div>
        <div className="white-line-container">
          <div className="white-line"></div>
        </div>

        <div className="delete-text">
        We're sorry to see you go. Are you sure you want to cancel your account? <br /> Please note that this action will result in the permanent loss of all your data.
        </div>

        <div className="white-line-container">
          <div className="white-line"></div>
        </div>

        <div className="buttons-delete-cont">
          <div className="buttons-dt-cont">
            <button
              type="button"
              className="btn btn-outline-light"
              onClick={() => {
                naviagte("/Settings");
              }}
            >
              No
            </button>
            <button
              type="button"
              className="btn btn-light"
              onClick={() => {
                deleteAccount()
              }}
            >
              Yes
            </button>
          </div>
        </div>
      </div>
      <div className="delete-footer">© Copyright {year} NDABee.</div>
    </div>
  );
};

export default DeleteAccount;
