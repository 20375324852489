import React, { useEffect, useState } from "react";
import defaultCheck from "../../images/default-check.png";
import rightCheck from "../../images/right-check.png";
import wrongCheck from "../../images/wrong-check.png";
import "./ChangePassword.css";
import axios from "axios";
import {
  BASE_URL,
  HEADERS,
  SERVICES,
  SERVICES_NAME,
  VERSION,
} from "../../Constants/Auth";
import { USER_ID } from "../../Constants/User";
import { useNavigate } from "react-router-dom";
import { Get } from "../../Apis/Members/Models/Get";
import { SavePassword } from "../../Apis/Accounts/Models/SavePassword";
import { toast, ToastContainer } from "react-toastify";
import { setAcitveNav } from "../../redux/features/userSlice";
import { useDispatch } from "react-redux";

const ChangePassword = () => {
  const [formulario, setFormulario] = useState({
    username: "",
    password: "",
    confirmPassword: "",
  });
  const [usernameError, setUsernameError] = useState("");
  const [lengthError, setLengthError] = useState("");
  const [characterError, setCharacterError] = useState("");
  const [numericError, setNumericError] = useState("");
  const [symbolError, setSymbolrror] = useState("");
  const baseUrl = BASE_URL + SERVICES + "/" + VERSION + "/" + SERVICES_NAME;
  const currentId = USER_ID();
  const navigate = useNavigate();
  const year = new Date().getFullYear();
  const dispatch = useDispatch();

  const handleFormChange2 = (e) => {
    const { name, value } = e.target;
    setFormulario({
      ...formulario,
      [name]: value,
    });

    const password = value;
    const username = formulario.username;

    let hasUsernameError =
      password.toLowerCase().includes(username.toLowerCase()) || !password;
    let hasLengthError = password.length < 6;
    let hasUppercaseError = !/[A-Z]/.test(password);
    let hasNumericError = !/\d/.test(password);
    let hasSymbolError = !/[!@#$%^&*()]/.test(password);

    setUsernameError(hasUsernameError);
    setLengthError(hasLengthError);
    setCharacterError(hasUppercaseError);
    setNumericError(hasNumericError);
    setSymbolrror(hasSymbolError);
  };

  const handleFormChange = (e) => {
    const { name, value } = e.target;
    setFormulario({
      ...formulario,
      [name]: value,
    });
  };

  const getUsername = async () => {
    try {
      const classRef = new Get();
      await classRef.List({ MembersId: parseInt(currentId) });
      let result = classRef.ResponseBody;
      if (result.MemberInfo) {
        setFormulario({
          ...formulario,
          ["username"]: result.MemberInfo.UserName,
        });
      }
    } catch (error) {
      console.log("Error", error);
    }
  };

  const changePassword = async () => {
    if (
      !usernameError &&
      !lengthError &&
      !characterError &&
      !numericError &&
      !symbolError &&
      formulario.password
    ) {
      console.log("Verify successfully");
    } else {
      return console.log("Errors on password");
    }

    const classRef = new SavePassword();
    await classRef.List({
      MembersId: parseInt(currentId),
      CurrentPassword: formulario.confirmPassword,
      NewPassword: formulario.password,
    });
    let result = classRef.ResponseBody;

    console.log("Result password change", result);
    if (result.Authentication) {
      toast.success("Update Successfully!");
      setTimeout(() => {
        dispatch(setAcitveNav("settings"))
        navigate("/Settings");
      }, 3000);
    }
  };

  useEffect(() => {
    getUsername();
  }, []);

  return (
    <>
      <div className="cpassword-principal-container">
        <div></div>
        <div className="cpassword-main-container">
          <div className="cpassword-title">Edit Password</div>
          <div
            className="col-12 mb-4"
            style={{ display: "flex", justifyContent: "center" }}
          >
            <div
              className="green-line mt-1 mb-1"
              style={{ width: "57px" }}
            ></div>
          </div>
          <div className="cpassword-form">
            <>
              <div
                className="mt-3 mb-3"
                style={{ display: "flex", flexDirection: "column" }}
              >
                <label
                  htmlFor="signup-confirm"
                  className="form-label label-title"
                >
                  Current Password
                </label>
                <input
                  type="password"
                  className="form-control"
                  style={{ padding: "10px", height: "52px" }}
                  id="signup-confirm"
                  placeholder="Current password"
                  autoComplete="off"
                  key="uniqueKey"
                  name="confirmPassword"
                  value={
                    formulario.confirmPassword ? formulario.confirmPassword : ""
                  }
                  onChange={handleFormChange}
                />
              </div>
              <div
                className="mb-4"
                style={{ display: "flex", flexDirection: "column" }}
              >
                <label
                  htmlFor="signup-username"
                  className="form-label label-title"
                >
                  Username
                </label>
                <input
                  type="text"
                  className="form-control"
                  style={{ padding: "10px", height: "52px" }}
                  id="signup-username"
                  placeholder="Username"
                  key="uniqueKey"
                  name="username"
                  defaultValue={formulario?.username}
                  readOnly
                />
              </div>

              <div
                className="mb-2"
                style={{ display: "flex", flexDirection: "column" }}
              >
                <label
                  htmlFor="signup-password"
                  className="form-label label-title"
                >
                  New Password
                </label>
                <input
                  type="password"
                  className="form-control"
                  style={{ padding: "10px", height: "52px" }}
                  id="signup-password"
                  placeholder="New Password"
                  autoComplete="off"
                  key="uniqueKey"
                  name="password"
                  value={formulario.password ? formulario.password : ""}
                  onChange={handleFormChange2}
                />
              </div>
              <div className="mb-2">
                <div className="verify-subcontainer">
                  <div>
                    <img
                      src={
                        usernameError === ""
                          ? defaultCheck
                          : !usernameError
                          ? rightCheck
                          : wrongCheck
                      }
                      alt="check"
                      width="18px"
                      height="18px"
                    />
                  </div>
                  <div
                    className="verify-password"
                    style={{
                      color: `${
                        usernameError === ""
                          ? "#676767"
                          : !usernameError
                          ? "#06B576"
                          : "red"
                      }`,
                    }}
                  >
                    Not contain the users account name.
                  </div>
                </div>
                <div className="verify-subcontainer">
                  <div>
                    <img
                      src={
                        lengthError === ""
                          ? defaultCheck
                          : !lengthError
                          ? rightCheck
                          : wrongCheck
                      }
                      alt="check"
                      width="18px"
                      height="18px"
                    />
                  </div>
                  <div
                    className="verify-password2"
                    style={{
                      color: `${
                        lengthError === ""
                          ? "#676767"
                          : !lengthError
                          ? "#06B576"
                          : "red"
                      }`,
                    }}
                  >
                    Exceeded six characters in length regardless of the minimum
                    password length control.
                  </div>
                </div>
                <div className="verify-subcontainer">
                  <div>
                    <img
                      src={
                        characterError === ""
                          ? defaultCheck
                          : !characterError
                          ? rightCheck
                          : wrongCheck
                      }
                      alt="check"
                      width="18px"
                      height="18px"
                    />
                  </div>
                  <div
                    className="verify-password"
                    style={{
                      color: `${
                        characterError === ""
                          ? "#676767"
                          : !characterError
                          ? "#06B576"
                          : "red"
                      }`,
                    }}
                  >
                    Contains at least one character in Upper Case.
                  </div>
                </div>
                <div className="verify-subcontainer">
                  <div>
                    <img
                      src={
                        numericError === ""
                          ? defaultCheck
                          : !numericError
                          ? rightCheck
                          : wrongCheck
                      }
                      alt="check"
                      width="18px"
                      height="18px"
                    />
                  </div>
                  <div
                    className="verify-password"
                    style={{
                      color: `${
                        numericError === ""
                          ? "#676767"
                          : !numericError
                          ? "#06B576"
                          : "red"
                      }`,
                    }}
                  >
                    Contains at least one numeric character.
                  </div>
                </div>
                <div className="verify-subcontainer">
                  <div>
                    <img
                      src={
                        symbolError === ""
                          ? defaultCheck
                          : !symbolError
                          ? rightCheck
                          : wrongCheck
                      }
                      alt="check"
                      width="18px"
                      height="18px"
                    />
                  </div>
                  <div
                    className="verify-password"
                    style={{
                      color: `${
                        symbolError === ""
                          ? "#676767"
                          : !symbolError
                          ? "#06B576"
                          : "red"
                      }`,
                    }}
                  >
                    Contains at least one character of the following Symbols:
                    !@#$%^&*().
                  </div>
                </div>
              </div>

              <div
                className="col-12"
                style={{ display: "flex", justifyContent: "center" }}
              >
                <div className="green-line mt-3 mb-4"></div>
              </div>

              <div className="col-12">
                <button
                  className="btn btn-primary w-100 mb-3"
                  style={{ height: "50px" }}
                  onClick={() => {
                    changePassword();
                  }}
                >
                  Update
                </button>
              </div>
            </>
          </div>
        </div>
        <div className="cpassword-footer">© Copyright {year} NDABee.</div>
      </div>
      <ToastContainer theme="colored" position="top-right" autoClose={2000} />
    </>
  );
};

export default ChangePassword;
