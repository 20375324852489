import React from 'react'
import Menu from '../../Components/Menu/Menu'
import { useNavigate } from 'react-router-dom';

const Privacy = () => {
  const year = new Date().getFullYear();
  const navigate = useNavigate();
  return (
    <div>
      <Menu/>
      <div className='term-fo-service-principal-container'>
        <div className='terms-container'>
          <h1 className='terms-title'>Privacy Policy</h1>
          <div className='green-line-terms'>
            <div className='green-line-term'></div>
          </div>
          <h2 className='terms-subtitle mt-5'>Effective Date: 04/04/24</h2>
          <p className='terms-text'>
          Welcome to ConfidentialBee! This Privacy Policy outlines how we collect, use, maintain, and disclose information obtained from users ("Users") of our website <a href="https://www.confidentialbee.com/" target='_blank' rel="noreferrer">www.confidentialbee.com</a> and our mobile application ("App"). This policy applies to all products and services offered by ConfidentialBee.</p>
          <ol>
            <li>
            <h3 className='terms-h3'>Information Collection:</h3>
            <p className='terms-text'>Personal Information: We may collect personal identification information from Users in various ways, including when they visit our site, register on the site, subscribe to our newsletter, respond to a survey, fill out a form, and in connection with other activities, services, features, or resources we make available on our Site or App. Users may be asked for, as appropriate, name, email address, mailing address, phone number, and other relevant details.</p>
            </li>
            <li>
            <h3 className='terms-h3'>How We Use Collected Information:</h3>
            <p className='terms-text'>We may use the information Users provide for various purposes, including:</p>
            <ul className='ul-list'>
              <li>To personalize user experience</li>
              <li>To improve our Site and App</li>
              <li>To send periodic emails or push notifications</li>
              <li>To process transactions</li>
              <li>To respond to inquiries, questions, and/or other requests</li>
            </ul>
            </li>
            <li>
            <h3 className='terms-h3'>Information Protection:</h3>
            <p className='terms-text'>We adopt appropriate data collection, storage, and processing practices and security measures to protect against unauthorized access, alteration, disclosure, or destruction of Users' personal information, username, password, transaction information, and data stored on our Site or App.</p>
            </li>
            <li>
            <h3 className='terms-h3'>Sharing Your Personal Information:</h3>
            <p className='terms-text'>We do not sell, trade, or rent Users' personal identification information to others. We may share generic aggregated demographic information not linked to any personal identification information regarding visitors and users with our business partners, trusted affiliates, and advertisers.</p>
            </li>
            <li>
            <h3 className='terms-h3'>Third-Party Websites:</h3>
            <p className='terms-text'>Users may find advertising or other content on our Site and App that links to the sites and services of our partners, suppliers, advertisers. We do not control the content or links that appear on these sites and are not responsible for the practices employed by websites linked to or from our Site or App.</p>
            </li>
            <li>
            <h3 className='terms-h3'>Changes to This Privacy Policy:</h3>
            <p className='terms-text'>ConfidentialBee has the discretion to update this privacy policy at any time. When we do, we will revise the updated date at the bottom of this page. We encourage Users to frequently check this page for any changes to stay informed about how we are helping to protect the personal information we collect. You acknowledge and agree that it is your responsibility to review this privacy policy periodically and become aware of modifications.</p>
            </li>
            <li>
            <h3 className='terms-h3'>Your Acceptance of These Terms:</h3>
            <p className='terms-text'>By using this Site or App, you signify your acceptance of this policy.</p>
            </li>
          </ol>
          <h2 className='terms-subtitle2 mt-5'>Closing Statement:</h2>
          <p className='terms-text'>We are committed to maintaining the privacy and security of your personal information. By using ConfidentialBee, you agree to the terms outlined in this Privacy Policy. If you have any questions or concerns about our practices or this policy, please contact us at info@softsmart.com Thank you for entrusting us with your information.</p>
        </div>
        <div className='before-footer'>Terms of Service | Privacy Policy</div>
        <div className='footer-terms'>© Copyright {year} NDABee.</div>
      </div>
    </div>
  )
}

export default Privacy