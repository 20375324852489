import React, { useEffect, useState } from "react";
import avatar from "../../images/avatar.png";
import plusAvatar from "../../images/addAvatar.png";
import "./ChangeAvatar.css";
import axios from "axios";
import {
  BASE_URL,
  HEADERS,
  SERVICES,
  SERVICES_NAME,
  VERSION,
} from "../../Constants/Auth";
import { USER_ID } from "../../Constants/User";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import { SaveAvatar } from "../../Apis/Members/Models/SaveAvatar";
import { useDispatch, useSelector } from "react-redux";
import { hideLoading, showLoading } from "../../redux/features/alertSlice";
import { GetAvatar } from "../../Apis/Members/Models/GetAvatar";
import Modal from "../../Components/Modal/Modal.jsx";
// import Modal from "react-bootstrap/Modal";
import Avatar from "react-avatar-edit";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import { setAcitveNav } from "../../redux/features/userSlice.js";

const ChangeAvatar = () => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [imagePreview, setImagePreview] = useState(null);
  const [updateImg, setUpdateImg] = useState(null);
  const [editImage, setEditImage] = useState(null);
  const baseUrl = BASE_URL + SERVICES + "/" + VERSION + "/" + SERVICES_NAME;
  const currentUserId = USER_ID();
  const navigate = useNavigate();
  const year = new Date().getFullYear();
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const [preview, setPreview] = useState(null);
  const [src, setSrc] = useState(null);
  const [modalShow, setModalShow] = useState(false);
  const [image, setImage] = useState("");
  const [cropData, setCropData] = useState("#");
  const [cropper, setCropper] = useState();

  const handleShow = () => setModalShow(true);
  const handleClose = () => setModalShow(false);

  function dataURLtoBlob(dataURL) {
    const byteString = atob(dataURL.split(",")[1]);
    const mimeString = dataURL.split(",")[0].split(":")[1].split(";")[0];
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ab], { type: mimeString });
  }

  const onClose = () => {
    setPreview(null);
  };

  const onCrop = (view) => {
    setPreview(view);
    console.log(view);
  };

  const b64toBlob = async (dataURI) => {
    const base64file = await fetch(dataURI)
    const blobFile = await base64file.blob()
    return blobFile
}

  const saveCrop = async () => {
    if (typeof cropper !== 'undefined') {
      let cropperCanvas = cropper.getCroppedCanvas()
      setCropData(cropperCanvas.toDataURL())
      const fileCropped = await b64toBlob(cropperCanvas.toDataURL())
      setImagePreview(cropperCanvas.toDataURL());
      setSelectedFile(cropperCanvas.toDataURL());
      console.log("Data",cropperCanvas.toDataURL())
      // setImageState({ file: fileCropped });

  }
    // setSelectedFile(preview);
    // setImagePreview(preview);
    setModalShow(false);
  };

  const onBeforeFileLoad = (elem) => {
    if (elem.target.files[0].size > 71680) {
      alert("El archivo es demasiado grande!");
      elem.target.value = "";
    }
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);

    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        // setImagePreview(e.target.result);
        setEditImage(null);
        setUpdateImg(true);
        setSrc(e.target.result); // Set the base64 string as the source for Avatar
        setModalShow(true); // Show the modal when the file is selected
      };
      reader.readAsDataURL(file);
    } else {
      setImagePreview(null);
      setUpdateImg(null);
    }
    event.target.value = null;
  };

  const handleUpload = async () => {
    if (!selectedFile) {
      console.log("No file selected");
      return;
    }

    try {
      const classRef = new SaveAvatar();
      const formData = new FormData();
      formData.append("MembersId", currentUserId);
      formData.append("File", dataURLtoBlob(selectedFile), "[PROXY]");

      await classRef.List(formData);
      let result = classRef.ResponseBody;
      if (result.FileInfo) {
        toast.success("Your avatar was updated successfully!");
        setTimeout(() => {
          dispatch(setAcitveNav("settings"))
          navigate("/Settings");
        }, 1500);
      }
    } catch (error) {
      console.log("Error", error);
    }
  };

  // const handleUpload = async () => {
  //   var formdata = new FormData();
  //   formdata.append("MembersId", currentUserId);
  //   formdata.append("File", selectedFile, "[PROXY]");

  //   try {
  //     const classRef = new SaveAvatar();
  //     await classRef.List(formdata);
  //     let result = classRef.ResponseBody;
  //     if (result.FileInfo) {
  //       toast.success("Your avatar was updated successfully!");
  //       setTimeout(() => {
  //         navigate("/Settings");
  //       }, 1500);
  //     }
  //   } catch (error) {
  //     console.log("Error", error);
  //   }
  // };

  const getAvatar = async () => {
    dispatch(showLoading());
    try {
      const classRef = new GetAvatar(currentUserId);
      await classRef.List();
      let result = classRef.ResponseBody;
      if (result) {
        setImagePreview(result);
      }
      setTimeout(() => {
        dispatch(hideLoading());
      }, 1500);
    } catch (error) {
      dispatch(hideLoading());
      console.log("Error", error);
    }
  };

  useEffect(() => {
    getAvatar();
  }, []);

  return (
    <>
      <div className="cavatar-principal-container">
        <div></div>
        <div className="cavatar-main">
          <div className="cavatar-container">
            <div className="cavatar-title mb-2">Edit Avatar</div>
            <div
              className="col-12 mb-5"
              style={{ display: "flex", justifyContent: "center" }}
            >
              <div className="green-line"></div>
            </div>
            <>
              <div className="signup-avatar-img">
                <img
                  src={imagePreview ? imagePreview : avatar}
                  alt="avatar"
                  width="390px"
                  height="390px"
                  className={imagePreview ? "input-file" : null}
                />
                <input
                  type="file"
                  id="fileInput"
                  accept=".jpg, .jpeg, .png"
                  onChange={handleFileChange}
                  style={{ display: "none" }}
                />
                <label htmlFor="fileInput" className="custom-file-button">
                  <img
                    src={plusAvatar}
                    alt="avatar"
                    width="55.29px"
                    height="55.29px"
                    className="plus-avatar"
                  />
                </label>
              </div>
              <div
                className="col-12 mt-4"
                style={{ display: "flex", justifyContent: "center" }}
              >
                <div className="green-line" style={{ height: "3.2px" }}></div>
              </div>
              <div className="col-12">
                <button
                  className="btn btn-primary w-100 mb-3"
                  style={{ height: "50px" }}
                  onClick={() => {
                    if (selectedFile) {
                      handleUpload();
                    } else {
                      toast.warning("Please select a file before continuing");
                    }
                  }}
                >
                  Update
                </button>
              </div>
              <div
                className="col-12"
                style={{ display: "flex", justifyContent: "center" }}
              >
                <div className="green-line"></div>
              </div>
            </>
          </div>
        </div>
        <div className="cavatar-footer">© Copyright {year} NDABee.</div>
      </div>
      <ToastContainer theme="colored" position="top-right" autoClose={2000} />
      <div
        className={`modal ${modalShow ? "show" : ""}`}
        style={{ display: modalShow ? "block" : "none", zIndex: 1050 }}
        tabIndex="-1"
      >
        <div className="modal-dialog modal-lg modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Edit your avatar</h5>
              <button
                type="button"
                className="btn-close"
                aria-label="Close"
                onClick={handleClose}
              ></button>
            </div>
            <div className="modal-body d-flex justify-content-center align-items-center">
              {/* <Avatar
               width={500}
               height={600}
               onCrop={onCrop}
               onClose={onClose}
               onBeforeFileLoad={onBeforeFileLoad}
               src={src ? src : avatar} // Use base64 string here
               borderRadius={0}
               cropRadius={0}
              /> */}
               <Cropper
                        aspectRatio={1 / 1}
                        src={src ? src : avatar}
                        zoomable={false}
                        minCropBoxHeight={200}
                        minCropBoxWidth={200}
                        background={false}
                        responsive={true}
                        cropBoxResizable={true}
                        checkOrientation={false}
                        onInitialized={(instance) => {
                            setCropper(instance);
                        }}
                        guides={true}
                    />
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                onClick={handleClose}
              >
                Close
              </button>
              <button
                type="button"
                className="btn btn-primary"
                onClick={saveCrop}
              >
                Save changes
              </button>
            </div>
          </div>
        </div>
      </div>
      {modalShow && (
        <div
          className="modal-backdrop fade show"
          style={{ zIndex: 1040 }}
        ></div>
      )}
      {/* <Modal 
      show={modalShow} 
      handleClose={handleClose} 
      title="Modal title"
      handleSave={saveCrop}
      >
        <Avatar
          width={450}
          height={500}
          onCrop={onCrop}
          onClose={onClose}
          onBeforeFileLoad={onBeforeFileLoad}
          src={imagePreview} // Use base64 string here
          borderRadius={0}
          cropRadius={0}
        />
      </Modal> */}

      {/* <Modal
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={modalShow}
        onHide={handleClose}
        className="modal-height"
        size="md"
        style={{ overflow: "auto" }}
      >
        <div>
          <Modal.Header closeButton style={{border:"none",width:"600px"}}></Modal.Header>
          <Modal.Body>
            <div
              style={{
                margin: "0 auto",
                display: "flex",
                justifyContent: "center",
                height:"400px",
                width:"600px"
              }}
            >
              <Avatar
                width={450}
                height={500}
                onCrop={onCrop}
                onClose={onClose}
                onBeforeFileLoad={onBeforeFileLoad}
                src={src} // Use base64 string here
                borderRadius={0}
                cropRadius={0}
              />
            </div>
          </Modal.Body>
          <Modal.Footer style={{ border: "none" }}>
            <button
              type="button"
              className="btn btn-primary"
              onClick={saveCrop}
            >
              Save
            </button>
          </Modal.Footer>
        </div>
      </Modal> */}
    </>
  );
};

export default ChangeAvatar;
